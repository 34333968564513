<template>
  <Slideover v-model="offering.show_documents" header="Offering Documents">
    <div class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full">

      <DragDrop v-if="offering.id" @changedFiles="updateDataTransferObj" :files="offeringDocuments" />
      <div v-else class=" flex gap-4 mx-16 my-10 p-4 rounded-lg text-gray-700 bg-gray-100 shadow dark:text-neutral-300 dark:shadow-none dark:bg-neutral-900">
        <div class="bg-yellow-50 dark:bg-yellow-950 rounded-full h-fit p-2">
          <ExclamationTriangleIcon class="size-5 text-yellow-400 dark:text-yellow-600" />
        </div>
        Please create the offering first before uploading documents. Thank you.
      </div>
      <ButtonSecondary text="Close" size="lg" @click="offering.show_documents = false" class="w-full mt-5" />

    </div>
  </Slideover>
</template>

<script setup>
// Essentials
import { ref, watch, onMounted } from 'vue';

// Components
import Slideover from '@/components/applicationui/Slideover.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';
import DragDrop from '@/components/widgets/DragDrop.vue';

// Stores
import { useOfferingsStore } from '@/stores/offerings';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
const offeringsStore = useOfferingsStore();

// Model
const offering = defineModel();

// State
const noDocuments = ref(true);
const dt = ref(new DataTransfer());
const offeringDocuments = ref([]);

// Fetch Documents from Supabase
const setOfferingDocuments = async (offering_id) => {
  offeringDocuments.value = await offeringsStore.getOfferingDocumentsSupabase(offering_id);
  offeringDocuments.value.forEach((doc) => { doc = { ...doc, ...doc.metadata } });
};

// Handle File Upload to Supabase
async function handleOfferingDocumentUpload(file) {
  if (!file) return;
  await offeringsStore.uploadOfferingDocumentSupabase(offering.value.id, file);
  await setOfferingDocuments(offering.value.id);
}

// Handle File Deletion from Supabase
async function deleteFile(file_name) {
  await offeringsStore.deleteOfferingDocument(offering.value.id, file_name);
  await setOfferingDocuments(offering.value.id);
}

// Update DataTransfer Object
const updateDataTransferObj = (dtObj) => {
  console.log(-1)
  dt.value = dtObj;
  handleFilesChanged();
  noDocuments.value = dt.value.files.length === 0;
};

// Handle Changed Files
async function handleFilesChanged() {
  const existingFiles = offeringDocuments.value.map((doc) => doc.name);
  // Handle New Files
  for (const file of dt.value.files) {
      if (!existingFiles.includes(file.name)) {
        console.log(3)
        await handleOfferingDocumentUpload(file);
      }
    }

    // Handle Deleted Files
    for (const fileName of existingFiles) {
      if (!Array.from(dt.value.files).some((file) => file.name === fileName)) {
        await deleteFile(fileName);
      }
    }
}

// Mounted
onMounted(async () => {
  await setOfferingDocuments(offering.value.id)
})
</script>
