import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
import { logErrorMessage } from '@/schemas/AdvancedErrorHandler.js'
// import { ref } from 'vue'


export const useTransactionsStore = defineStore('transactions', () => {
  // State (refs)

  // Getters, (computed properties)

  // Actions, (functions)
  async function getAllTransactionsPaginated(start = 0, finish = 10000000) {
    const { data, error } = await supabase.from('transactions').select(`*, offerings(*), accounts(*, accounts_protected(*), parties!accounts_primary_party_id_fkey(id, contact_email)), partners(*)`)
      .order('created_at', { ascending: false })
      .range(start, finish)
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }

  async function getAllTransactionByAccountId(account_id) {
    if (!account_id) return
    const { data, error } = await supabase.from('transactions').select('*, offerings(*)').eq('account_id', account_id).order('created_at', { ascending: false })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  };

  async function getAllSettledTransactionsByAccountId(account_id) {
    if (!account_id) return
    const { data, error } = await supabase.from('transactions').select('*, offerings(*)').eq('account_id', account_id).eq('status', 'settled').order('created_at', { ascending: false })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  };

  async function getCashBalance(account_id) {
    const { data, error } = await supabase.from('transactions').select('*').eq('account_id', account_id).eq('status', 'settled').eq('units', 'cash')
    if (error) { console.log(await logErrorMessage(error)); return }
    if (data.length == 0) return 0

    let cashBalance = 0
    data.forEach(transaction => {
      if (transaction.type == 'deposit') cashBalance += transaction.amount
      else if (transaction.type == 'withdraw') cashBalance -= transaction.amount
    })

    return cashBalance
  }

  async function updateTransactionStatus(id, status) {
    const { error } = await supabase.from('transactions').update({ status: status }).eq('id', id)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  async function updateTradeStatus(account_id, tapi_trade_id, tapi_account_id, status, partner_id) {
    var body = {
      id: account_id,
      tradeId: tapi_trade_id,
      accountId: tapi_account_id,
      orderStatus: status,
      partner_id: partner_id
    }
    const { error } = await supabase.functions.invoke('update-trade-status', { body })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  async function createTradeInTapi(account_id, tapi_account_id, tapi_offering_id, offeringName, units, paymentType = 'TBD') {
    // If the payment type is not one of the following accepted types for TAPI set it to TBD
    if (paymentType != 'ACH' && paymentType != 'WIRE' && paymentType != 'CHECK' && paymentType != 'CREDITCARD' && paymentType != 'IRA' && paymentType != 'TBA') paymentType = 'TBD' 
    
    var body = {
      id: account_id,
      offeringId: tapi_offering_id,
      accountId: tapi_account_id,
      transactionType: paymentType,
      transactionUnits: units,
      createdIpAddress: '23.30.55.109',
      field1: offeringName,
    }
    const { data, error } = await supabase.functions.invoke('create-trade', { body })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data.purchaseDetails[1][0].tradeId
  }

  async function createTransactionInSupabase(tapi_trade_id, account_id, offering_id, units, offering_unit_price, partner_id, amount, type = 'buy', transaction_method) {
    const { data, error } = await supabase.from('transactions').insert({
      account_id: account_id,
      offering_id: offering_id,
      tapi_trade_id: tapi_trade_id,
      partner_id: partner_id,
      type: type,
      amount: amount,
      units: units,
      price_per_unit: offering_unit_price,
      transaction_method: transaction_method?.toLowerCase() ?? 'tbd'
    }).select('id')
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0].id
  }

  async function requestAchFundsTransferInTapi(account_id, tapi_trade_id, tapi_account_id, tapi_offering_id, offering_name, investmentTotal) {
    // Get the ACH account details from TAPI
    let ach = await getAchAccountDetailsFromTapi(account_id, tapi_account_id)
    if (!ach || ach.statusCode != '101' || !ach?.statusDesc?.AccountNickName) return

    // Set the NickName
    const NickName = ach.statusDesc.AccountNickName

    var body = {
      id: account_id,
      accountId: tapi_account_id,
      offeringId: tapi_offering_id,
      tradeId: tapi_trade_id,
      NickName: NickName,
      amount: investmentTotal,
      description: `Investment in ${offering_name}`,
      checkNumber: tapi_trade_id
    }
    const { error } = await supabase.functions.invoke('request-ach-transfer', { body })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  async function requestCcFundsTransferInTapi(account_id, tapi_trade_id, tapi_account_id, investmentTotal) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
      tradeId: tapi_trade_id,
      amount: investmentTotal,
      createdIpAddress: '23.30.55.109',
    }
    const { error } = await supabase.functions.invoke('request-cc-transfer', { body })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  async function emailSubscriptionDocument(tapi_trade_id, account_id, tapi_account_id, tapi_offering_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
      tradeId: tapi_trade_id,
      offeringId: tapi_offering_id
    }
    const { error } = await supabase.functions.invoke('email-subscription-document', { body })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  async function getSubscriptionDocument(account_id, tapi_account_id, tapi_offering_id, tapi_trade_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
      offeringId: tapi_offering_id,
      tradeId: tapi_trade_id
    }
    const { data, error } = await supabase.functions.invoke('get-subscription-documents', { body })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }

  async function getTradesFromTapi(account_id, tapi_account_id) {
    var body = { 
      id: account_id,
      accountId: tapi_account_id
    }
    const { data, error } = await supabase.functions.invoke('get-trades', { body })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }

  async function updateTransactionSettledAt(trade_id) {
    const { data, error } = await supabase.from('transactions').update({ settled_at: new Date() }).eq('id', trade_id)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  async function updateTransactionRefundedAt(trade_id) {
    const { data, error } = await supabase.from('transactions').update({ refunded_at: new Date() }).eq('id', trade_id)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  // Not Callable Functions

  // There is another version of this call in accountsStore called getAchAccount which is used to get the ach account details from TAPI this is here locally for convience
  async function getAchAccountDetailsFromTapi(account_id, tapi_account_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
      types: 'Account'
    }
    const { data, error } = await supabase.functions.invoke('get-ach-account', { body })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }

  return {
    getAllTransactionsPaginated,
    getAllTransactionByAccountId,
    getAllSettledTransactionsByAccountId,
    getCashBalance,
    updateTransactionStatus,
    createTradeInTapi,
    createTransactionInSupabase,
    requestAchFundsTransferInTapi,
    requestCcFundsTransferInTapi,
    emailSubscriptionDocument,
    getSubscriptionDocument,
    getTradesFromTapi,
    updateTradeStatus,
    updateTransactionSettledAt,
    updateTransactionRefundedAt
  }
})