<template>
  <SlickList axis="y" v-model:list="items" class="flex flex-col gap-3">
    <SlickItem v-for="(item, i) in items" :key="item" :index="i" class="z-[999]">
      <div class="px-3 py-2 bg-white dark:bg-neutral-900 rounded-lg flex gap-1 items-center shadow-opera">
        <!-- <DragHandle> -->
        <div class="flex cursor-grab dark:text-neutral-300">
          <EllipsisVerticalIcon class="size-5 -mr-[7px]" />
          <EllipsisVerticalIcon class="size-5 -ml-[7px]" />
        </div>
        <!-- </DragHandle> -->
        <div class="flex gap-2 items-center">
          <div class="dark:text-neutral-300">{{ item?.name ?? item?.header }}</div>
          <LinkIcon v-if="item?.type == 'links'" class="size-5 text-blue-600" />
          <SwatchIcon v-if="item?.type == 'key_values'" class="size-5 text-purple-600" />
          <ListBulletIcon v-if="item?.type == 'content'" class="size-5 text-green-600" />
          <PhotoIcon v-if="item?.type == 'image'" class="size-5 text-pink-600" />
        </div>
      </div>
    </SlickItem>
  </SlickList>
</template>

<script setup>
import { SlickList, SlickItem } from 'vue-slicksort';
import { EllipsisVerticalIcon, LinkIcon, SwatchIcon, ListBulletIcon, PhotoIcon } from '@heroicons/vue/24/outline';

const items = defineModel({ default: [] })
</script>
