<template>
  <Slideover v-model="offering.show_scores" header="Offering Scores">
    <div class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full">

      <!-- Scores Content -->
      <div class="flex-1">
        <div v-if="partnerStore.partner.partners_protected.show_scores">
          <!-- Scores Section -->
          <div class="flex flex-col gap-10">
            <!-- Financials -->
            <div class="flex flex-col gap-5">
              <Input v-model="offering.financials" label="Financial Score" tooltip="Leave blank or put 'Not yet available'." id="finance_score" placeholder="0-5" class="w-1/2" />
              <TextareaCustom v-model="offering.financials_explanation" label="Financial Score Explanation" tooltip="A brief explanation of the reasons for the given score." :rows="3" />
            </div>

            <!-- AUM -->
            <div class="flex flex-col gap-5">
              <Input v-model="offering.aum" label="AUM Score" tooltip="Leave blank or put 'Not yet available'." id="aum" placeholder="0-5" class="w-1/2" />
              <TextareaCustom v-model="offering.aum_explanation" label="AUM Score Explanation" tooltip="A brief explanation of the reasons for the given score." :rows="3" />
            </div>

            <!-- Management Team -->
            <div class="flex flex-col gap-5">
              <Input v-model="offering.management" label="Management Score" tooltip="Leave blank or put 'Not yet available'." id="management_score" placeholder="0-5" class="w-1/2" />
              <TextareaCustom v-model="offering.management_explanation" label="Management Score Explanation" tooltip="A brief explanation of the reasons for the given score." :rows="3" />
            </div>

            <!-- Longevity -->
            <div class="flex flex-col gap-5">
              <Input v-model="offering.longevity" label="Fund Longevity Score" tooltip="Leave blank or put 'Not yet available'." id="longevity_score" placeholder="0-5" class="w-1/2" />
              <TextareaCustom v-model="offering.longevity_explanation" label="Fund Longevity Score Explanation" tooltip="A brief explanation of the reasons for the given score." :rows="3" />
            </div>

            <!-- Documents -->
            <div class="flex flex-col gap-5">
              <Input v-model="offering.documents_score" label="Documents Score" tooltip="Leave blank or put 'Not yet available'." id="documents_score" placeholder="0-5" class="w-1/2" />
              <TextareaCustom v-model="offering.documents_explanation" label="Documents Score Explanation" tooltip="A brief explanation of the reasons for the given score." :rows="3" />
            </div>

            <!-- Liquidity -->
            <div class="flex flex-col gap-5">
              <Input v-model="offering.liquidity" label="Liquidity Score" tooltip="Leave blank or put 'Not yet available'." id="liquidity_score" placeholder="0-5" class="w-1/2" />
              <TextareaCustom v-model="offering.liquidity_explanation" label="Liquidity Score Explanation" tooltip="A brief explanation of the reasons for the given score." :rows="3" />
            </div>

            <!-- Fees -->
            <div class="flex flex-col gap-5">
              <Input v-model="offering.fee_score" label="Fees & Expenses Score" tooltip="Leave blank or put 'Not yet available'." id="fee_score" placeholder="0-5" class="w-1/2" />
              <TextareaCustom v-model="offering.fee_explanation" label="Fees & Expense Score Explanation" tooltip="A brief explanation of the reasons for the given score." :rows="3" />
            </div>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <div class="flex justify-end gap-5">
        <ButtonSecondary text="Close" size="lg" @click="offering.show_scores = false" class="w-full mt-10" />
      </div>

    </div>
  </Slideover>
</template>

<script setup>
// Essentials
import { usePartnerStore } from '@/stores/partner';
const partnerStore = usePartnerStore();

// Components
import Slideover from '@/components/applicationui/Slideover.vue';
import Input from '@/components/applicationui/Input.vue';
import TextareaCustom from '@/components/applicationui/TextareaCustom.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';

// Model
const offering = defineModel()

</script>