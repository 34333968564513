<template>
  <Popup v-model="open">
    <div class="sm:flex sm:items-start">
      <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 dark:bg-red-950 rounded-full sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationTriangleIcon class="w-6 h-6 text-red-600" aria-hidden="true" />
      </div>
      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
        <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-neutral-300">Archive Offering</DialogTitle>
        <div class="mt-2">
          <p class="text-sm text-gray-500 dark:text-neutral-400">Are you sure you want to archive this offering? Doing so will mean this offering will no longer be available for investors advisors
            and admins. Holdings and transactions previously made for this offering will still be visible.</p>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-4 flex gap-4 justify-end">
      <ButtonSecondary @click=" open = false" text="Cancel" size="lg" />
      <ButtonPrimary @click="handleConfirmClick" text="Archive" size="lg" color="red" />
    </div>
  </Popup>
</template>

<script setup>
// Components
import Popup from '@/components/applicationui/Popup.vue'
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';
// Libraries
import { DialogTitle } from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
// Emits
const emit = defineEmits(['confirmed'])
// Model
const open = defineModel(false)
// Simple Functions
const handleConfirmClick = () => {
  emit('confirmed')
}
</script>