<template>
  <Slideover v-model="offering.show_links" header="Offering Links">
    <div class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full">
      <div v-if="offering?.links?.length > 0" class="flex flex-col gap-5">
        <!-- Link Edit Card -->
        <div v-for="(link, i) in offering.links" :key="i" class="flex flex-wrap gap-5 bg-white dark:bg-neutral-900 shadow p-5 rounded-xl">

          <div class="flex-1 flex flex-wrap gap-5">
            <!-- Link Name -->
            <div class="min-w-40 max-w-60">
              <label for="website" class="!flex justify-between items-end input_label">
                Display Name
                <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
              </label>
              <div class="input_wrapper">
                <input type="text" name="website" id="website" class="input" placeholder="ex. Offering Info" v-model="link.name" />
              </div>
            </div>
            <!-- Link URL -->
            <div class="flex-grow min-w-52">
              <label for="website" class="!flex justify-between items-end input_label">URL</label>
              <div class="input_wrapper">
                <input type="text" name="website" id="website" class="input" placeholder="https://example.com" v-model="link.url" />
              </div>
            </div>
            <!-- Link Type -->
            <div class="w-40">
              <div class="select_menu_label">Type</div>
              <SelectMenu v-model="link.type" :items="['website', 'facebook', 'linkedin', 'twitter']" />
            </div>
            <!-- Link Demo -->
            <div class="w-52">
              <div class="select_menu_label">Preview</div>
              <div class="text-blue-600 flex gap-2 items-center cursor-pointer w-fit hover:text-blue-700 mt-3">
                <LinkIcon v-if="link.type == 'website'" class="w-6 h-6" />
                <svg v-else-if="link.type == 'twitter'" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
                <svg v-else-if="link.type == 'facebook'" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clip-rule="evenodd" />
                </svg>
                <svg v-else-if="link.type == 'instagram'" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clip-rule="evenodd" />
                </svg>
                <svg v-else-if="link.type == 'linkedin'" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                    fill-rule="evenodd" clip-rule="evenodd" />
                </svg>
                <span class="w-48 truncate underline font-medium">{{ link.name }}</span>
              </div>
            </div>
          </div>

          <!-- Link Delete -->
          <div>
            <TrashIcon @click="offering.links.splice(i, 1)" class="size-5 mb-3 cursor-pointer hover:text-red-700 transition-all ease-in-out duration-300" />
          </div>
        </div>

        <!-- Add Links Button -->
        <ButtonSecondary text="New Link" size="md" :icon="LinkIcon" @click="() => { offering.links.push({ id: `${uuidv4()}`, name: '', url: '', type: 'website' }) }" class="w-fit self-center" />

      </div>

      <!-- Empty State -->
      <div v-else class="text-center sm:mt-10">
        <LinkIcon class="mx-auto size-12 text-gray-400 dark:text-neutral-500" />
        <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-white">No links</h3>
        <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">Get started by creating a new link.</p>
        <div class="mt-6">
          <ButtonPrimary text="New Link" size="xl" :icon="LinkIcon" @click="() => { offering.links.push({ id: `${uuidv4()}`, name: '', url: '', type: 'website' }) }" />
        </div>
      </div>

      <ButtonSecondary text="Close" size="lg" @click="offering.show_links = false" class="w-full mt-5" />

    </div>
  </Slideover>
</template>

<script setup>
// Essentials
import { watch } from 'vue'
import { v4 as uuidv4 } from 'uuid';

// Components
import Slideover from '@/components/applicationui/Slideover.vue';
import SelectMenu from '@/components/applicationui/SelectMenu.vue';
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';

// Libraries
import { TrashIcon } from '@heroicons/vue/24/outline';
import { LinkIcon } from '@heroicons/vue/20/solid';

// Model
const offering = defineModel()

// Watchers
watch(() => offering.value.links, (newLinksArray) => {
  if (!newLinksArray) return
  if (newLinksArray?.length > 0) {
    // If the links array is not empty and a links object already exists in the additional content array do nothing
    if (offering.value.content.some(obj => obj.type === 'links')) return
    // If the links array is not empty and a links object does not exist in the additional content array add it
    offering.value.content.push({ id: 'links', name: 'Links Section', type: 'links' })
  }
  // If the links array is empty remove the links object from the additional content array
  if (newLinksArray?.length === 0) offering.value.content = offering.value.content.filter(obj => obj.type !== 'links')
}, { deep: true })
</script>