import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { logErrorMessage } from '@/schemas/AdvancedErrorHandler.js'
import * as Sentry from "@sentry/vue";

export const useUserStore = defineStore('user', () => {
  // State (refs)
  const router = useRouter()
  const user = ref(null)

  // Getters, (computed properties)
  const getDefaultAvatar = computed(() => {
    return null
  })

  // Actions, (functions)
  async function setUser() {
    // Get user id
    const user_id = await getUserID()
    // If no user id, return null
    if (!user_id) return
    // Get profile & profiles_protected for user
    user.value = await getUser(user_id)
    // If no profile, return null
    if (!user.value) return
    // Set Sentry user context if not already set
    Sentry.setUser({ id: user?.value?.id, email: user?.value?.email, partner_id: user?.value?.profiles_protected?.partner_id, role: user?.value?.profiles_protected?.role });
    // Set User Theme (this can be done in the background, no need to wait for it to finish)
    setTheme(user.value?.theme)
    //Get avatar
    user.value.avatar = await getAvatar(user.value?.has_avatar, user.value?.id)
  }
  async function getAvatar(has_avatar, user_id) {
    if (has_avatar) {
      const { data, error } = await supabase.storage.from('avatars').createSignedUrl(`${user_id}/profile`, 3600)
      if (error) return getDefaultAvatar
      return data.signedUrl
    }
    else return getDefaultAvatar
  }
  async function sendMagicLink(email, redirect, partner, site_url, logo_url, login_email_type = null) {
    const options = {
      redirectTo: redirect,
      data: { partner_id: partner.id }
    }
    var body = {
      email: email,
      options: options,
      partner: partner,
      siteUrl: site_url,
      logoUrl: logo_url,
      loginEmailType: login_email_type
    }
    const { error } = await supabase.functions.invoke('magic-link', { body })
    if (error) { console.log(await logErrorMessage(error)); return { success: false, error: error } }
    return { success: true }
  }
  async function otpVerify(email, token) { 
    const { error } = await supabase.auth.verifyOtp({ email, token, type: 'email' })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function getLastLoginDate() {
    const { data: { user } } = await supabase.auth.getUser()
    if (!user || !user.last_sign_in_at) return
    return user.last_sign_in_at
  }
  async function signOut(partner_meta_name = 'opera') {    
    await supabase.auth.signOut();
    let loginUrl = '/login' + `?partner=${partner_meta_name}`
    router.push(loginUrl);
  }

  // Not Callable Functions
  async function getUserID() {
    const auth = await supabase.auth.getUser();
    return auth.data.user?.id
  }
  async function getUser(user_id) {
    const { data, error } = await supabase.from('profiles').select('*, profiles_protected(*), parties!party_id(*, parties_protected(*))').eq('id', user_id).limit(1)
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0]
  }
  const setTheme = (theme) => {
    if (theme == 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.classList.add('dark')
        localStorage.theme = 'dark'
      }
      else {
        document.documentElement.classList.remove('dark')
        localStorage.theme = 'light'
      }
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', ({ matches }) => {
        if (matches) {
          document.documentElement.classList.add('dark')
          localStorage.theme = 'dark'
        } else {
          document.documentElement.classList.remove('dark')
          localStorage.theme = 'light'
        }
      })
    }
    else if (theme == 'dark') document.documentElement.classList.add('dark')
    else if (theme == 'light') document.documentElement.classList.remove('dark')
  }

  return {
    user,
    setUser,
    getAvatar,
    otpVerify,
    sendMagicLink,
    getLastLoginDate,
    signOut
  }
})