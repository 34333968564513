<template>
  <div class="flex flex-col">
    <!-- Drag & Drop Area -->
    <div @drop.prevent="drop" @dragenter.prevent @dragover.prevent="hoverClass = 'bg-blue-100 bg-opacity-70 dark:bg-blue-900 dark:bg-opacity-70'" @dragleave.prevent="hoverClass = ''"
      class="@apply h-[150px] p-4 grow flex items-center justify-center gap-2.5 rounded-2xl border-2 border-dashed border-border dark:border-neutral-500" :class="hoverClass">
      <div class="dark:text-neutral-300">
        Drag & Drop Files or
        <b class="cursor-pointer text-operacolor hover:text-operacolorlight" @click="simulateClick()">Browse Files</b>
      </div>
      <input ref="chooseFile" type="file" id="upload" class="hidden" @change="fileAddedViaBrowse" multiple />
    </div>

    <!-- Uploaded Files Display -->
    <div class="flex flex-wrap gap-y-5 mt-5 h-fit">
      <DocumentsList :documents="dtView" @remove="removeFile" />
    </div>
  </div>
</template>
<script setup>
// Essentials
import { ref, watch } from 'vue';
// Components
import DocumentsList from '@/components/ui/DocumentsList.vue';

// Props
const props = defineProps({
  files: { type: Array, required: false, default: () => [] }, // Existing files
});

// State
const hoverClass = ref('');
const dt = ref(new DataTransfer());
const dtView = ref([]);
const chooseFile = ref(null);

// Emits
const emit = defineEmits(['changedFiles']);

// Lifecycle Hook - Initialize Existing Files
watch(
  () => props.files,
  (newFiles) => {
    // Populate dtView and dt with existing files
    dtView.value = [];
    dt.value = new DataTransfer();

    newFiles.forEach((file) => {
      dtView.value.push({
        name: file.name,
        url: file.url || '', // URL for display (if available)
        important: file.type !== 'application/pdf' ? ' !important' : '',
        size: file.size || file.metadata.size, // Add size if available
      });

      // Create a mock `File` object for DataTransfer if not already a File
      const fileObj = new File([], file.name, { type: file.type || 'application/octet-stream' });
      dt.value.items.add(fileObj);
    });

    emit('changedFiles', dt.value);
  },
  { immediate: true, deep: true }
);

// Functions
const drop = (e) => {
  hoverClass.value = '';
  addFiles(e.dataTransfer.files);
};

const fileAddedViaBrowse = (event) => {
  addFiles(event.target.files);
};

const addFiles = (files) => {
  for (let file of files) {
    // Skip duplicates
    if (dtView.value.some((f) => f.name === file.name)) continue;

    if (!['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)) {
      alert('Accepted file types are jpg, png, & pdf.');
    } else if (file.size > 100000000) {
      alert('Maximum file size is 100MB.');
    } else {
      dt.value.items.add(file);

      const url = file.type !== 'application/pdf' ? URL.createObjectURL(file) : '';
      const important = file.type !== 'application/pdf' ? ' !important' : '';
      dtView.value.push({ name: file.name, url, important, size: file.size });

      emit('changedFiles', dt.value);
    }
  }
};

const removeFile = (fileName) => {
  const dtTemp = new DataTransfer();

  for (let file of dt.value.files) {
    if (file.name !== fileName) {
      dtTemp.items.add(file);
    }
  }

  dt.value = dtTemp;

  dtView.value = dtView.value.filter((file) => file.name !== fileName);
  emit('changedFiles', dt.value);
};

const simulateClick = () => {
  chooseFile.value.click();
};
</script>


