import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

const pinia = createPinia()
const app = createApp(App)

import * as Sentry from "@sentry/vue";
Sentry.init({
  app,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  dsn: "https://74dbf5a63068e840c5e7d4a4875a6f0c@o4506865052221440.ingest.us.sentry.io/4508580654219264",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      unmask: ['sentry-unmask', '.sentry-unmask']
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: [""],
  // Session Replay
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 50%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// import * as Sentry from "@sentry/vue";
// if (process.env.VUE_APP_SUPABASE_URL != 'http://127.0.0.1:54321') {
//   Sentry.init({
//     app,
//     dsn: "https://8730cbefdc61ab368b39dfb3399249b7@o4506865052221440.ingest.us.sentry.io/4507261399007232",
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration(),
//     ],
//     environment: process.env.ENVIRONMENT,
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/operaalts\.com/, /^https:qa\/\/operaalts\.com/, /^https:sandbox\/\/operaalts\.com/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }



app.use(router)
app.use(pinia)
app.mount('#app')
