<template>
  <div>
    <label v-if="props.label" for="textarea" class="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">
      {{ props.label }}
      <Tooltip v-if="props.tooltip" :content="props.tooltip">
        <QuestionMarkCircleIcon class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight" />
      </Tooltip>
    </label>
    <div class="mt-2">
      <textarea name="textarea" :rows="props.rows" v-model="paragraph" :required="props.required" :disabled="props.disabled" :placeholder="props.placeholder"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 text-sm leading-6 dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-800 dark:placeholder:text-neutral-600 focus:ring-2 focus:ring-inset focus:ring-operacolor dark:focus:ring-operacolor disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 disabled:ring-gray-200 dark:disabled:text-neutral-400 dark:disabled:ring-0"></textarea>
    </div>
  </div>
</template>

<script setup>
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'

// Components
import Tooltip from '@/components/applicationui/Tooltip.vue'

// Model
const paragraph = defineModel()

// Props
const props = defineProps({
  label: { type: String, required: false, default: null },
  tooltip: { type: String, required: false, default: null },
  rows: { type: Number, required: false, default: 2 },
  required: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  placeholder: { type: String, required: false, default: '' }
})
</script>