import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
import * as helper from '@/helper/helper';
import { logErrorMessage } from '@/schemas/AdvancedErrorHandler.js'
// import { ref, computed } from 'vue'


export const useOfferingsStore = defineStore('offerings', () => {
  // State (refs)

  // Getters, (computed properties)

  // Actions, (functions)
  async function getAllOfferingsPaginated(start = 0, finish = 10000000) {
    const { data, error } = await supabase.from('offerings').select('*, partners(*)').eq('archived', false)
      .order('created_at', { ascending: false })
      .range(start, finish)
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  };
  async function getAllOfferingsForPartnerPaginated(id, start = 0, finish = 10000000) {
    const { data, error } = await supabase.from('offerings').select('*, partners(*)').eq('partner_id', id).eq('archived', false)
      .order('created_at', { ascending: false })
      .range(start, finish)
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  };
  async function getOfferingById(id) {
    const { data, error } = await supabase.from('offerings').select('*, partners(*)').eq('id', id).limit(1);
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0]
  }
  async function getAllApprovedOfferingsForPartner(partner_id, user) {
    const { data, error } = await supabase.from('offerings').select('*').eq('partner_id', partner_id).eq('status', 'approved').eq('archived', false).order('created_at', { ascending: false });
    if (error) { console.log(await logErrorMessage(error)); return }

    // Handle custom visibility
    const offerings = [];
    if (data && data.length > 0 && user) {
      const userGroups = user.profiles_protected?.groups || [];

      data.forEach((offering) => {
        if (offering.visibility === 'custom' && offering.allowed_user_groups) {
          // Check if any user group matches any allowed offering group
          let visible = offering.allowed_user_groups.some(group => userGroups.includes(group));
          if (visible) offerings.push(offering);
        }
        else offerings.push(offering);
      });
    }

    return offerings
  };
  async function createOffering(offering) {
    const { data, error } = await supabase.from('offerings').insert(offering).select('*');
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0]
  }
  async function updateOffering(offering) { // Update the offering
    const { error } = await supabase.from('offerings').update(offering).eq('id', offering.id);
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function createOfferingInTapi(offering) { // Expect and offering object
    let body = {
      issueName: offering.name,
      issueType: helper.simpleCapitalize(offering.offering_type),
      targetAmount: offering.target_amount,
      minAmount: offering.minimum,
      maxAmount: offering.maximum,
      unitPrice: offering.unit_price,
      startDate: formatOfferingYYYYMMDDStringToMMDDYYYY(offering.start_date),
      endDate: formatOfferingYYYYMMDDStringToMMDDYYYY(offering.end_date),
      offeringText: offering.slogan,
      stampingText: '.',
      field1: 'Opera',
      partner_id: offering?.partner_id
    };
    const { data, error } = await supabase.functions.invoke('create-offering', { body: body });
    if (error) { console.log(await logErrorMessage(error)); return }
    return data.offeringDetails[1][0].offeringId //Return the offering ID
  }

  async function updateOfferingInTapi(offering) { // Expect and offering object validated by OfferingSchema
    let body = {
      offeringId: offering.tapi_offering_id,
      issueName: offering.name,
      issueType: helper.simpleCapitalize(offering.offering_type),
      targetAmount: offering.target_amount,
      minAmount: offering.minimum,
      maxAmount: offering.maximum,
      unitPrice: offering.unit_price,
      startDate: formatOfferingYYYYMMDDStringToMMDDYYYY(offering.start_date),
      endDate: formatOfferingYYYYMMDDStringToMMDDYYYY(offering.end_date),
      offeringText: offering.slogan,
      stampingText: '.',
      field1: 'Opera',
      partner_id: offering.partner_id
    };
    const { error } = await supabase.functions.invoke('update-offering', { body: body });
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  async function uploadImagesToSupabase(id, logoImage, bannerImage) {
    if (logoImage) {
      const { error } = await supabase.storage.from('offerings').upload(`${id}/logo`, logoImage, { cacheControl: '3600', upsert: true })
      if (error) { console.log(await logErrorMessage(error)); return }
    }
    if (bannerImage) {
      const { error } = await supabase.storage.from('offerings').upload(`${id}/banner`, bannerImage, { cacheControl: '3600', upsert: true })
      if (error) { console.log(await logErrorMessage(error)); return }
    }
    return true
  };
  async function uploadOfferingImagesToSupabase(offering_id, name, image) {
    if (!offering_id) { console.log('No Offering Id provided'); return }
    if (!name) { console.log('No image name provided'); return }
    if (!image) { console.log('No image file provided'); return true } // This is expected to be empty when the file has already been uploaded

    // Upload the image to the offerings folder
    const { error } = await supabase.storage.from('offerings').upload(`${offering_id}/${name}`, image, { cacheControl: '3600', upsert: true })
    if (error) { console.log(await logErrorMessage(error)); return }

    return true
  };
  async function uploadOfferingDocumentSupabase(offering_id, file) {
    if (!offering_id) { console.log(await logErrorMessage('No Offering ID Found')); return }
    if (!file) { return }
    // Upload the image to the offering documents folder
    const { error } = await supabase.storage.from('offerings').upload(`${offering_id}/documents/${file.name}`, file, { cacheControl: '3600', upsert: true })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function archiveOffering(id) {
    const { error } = await supabase.from('offerings').update({ archived: true }).eq('id', id);
    if (error) { console.log(error); return }
    return true
  };
  async function deleteOfferingDocument(offering_id, file_name) {
    const { error } = await supabase.storage.from('offerings').remove([`${offering_id}/documents/${file_name}`])
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function getOfferingFile(id, tapi_id, file) {
    // Check for image in new folder
    const url = await checkForFile(id, file)
    if (url) return url
    // Check for image in old folder
    const url2 = await checkForFile(tapi_id, file)
    if (url2) return url2
  }
  async function getOfferingDetails(tapi_offering_id) {
    const { data, error } = await supabase.functions.invoke('get-offering-details', { body: { 'offeringId': tapi_offering_id } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data['Offering purchased details'][0]
  }
  async function getOfferingDocuments(tapi_offering_id) {
    const { data, error } = await supabase.functions.invoke('get-offering-documents', { body: { 'offeringId': tapi_offering_id } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data?.document_details ?? []
  }
  async function getOfferingDocumentsSupabase(offering_id) {
    const { data, error } = await supabase.storage.from('offerings').list(`${offering_id}/documents`, { limit: 100, offset: 0, sortBy: { column: 'name', order: 'asc' } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }

  async function updateOfferingAllowedUserGroups(offering_id, allowed_user_groups) {
    const { error } = await supabase.from('offerings').update({ allowed_user_groups }).eq('id', offering_id)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  // Not Callable Functions
  async function checkForFile(folder, fileName) {
    const { data, error } = await supabase.storage.from('offerings').list(folder, { limit: 100, offset: 0, sortBy: { column: 'name', order: 'asc' } })
    if (error) { console.log(await logErrorMessage(error)); return }
    const hasFile = data.find((file) => file.name === fileName)
    if (hasFile) {
      const response = supabase.storage.from('offerings').getPublicUrl(`${folder}/${fileName}`)
      return response.data.publicUrl
    }
  }
  function getPublicFileUrl(offering_id, fileName) {
    const { data, error } = supabase.storage.from('offerings').getPublicUrl(`${offering_id}/${fileName}`)
    if (error) { console.log(logErrorMessage(error)); return }
    return data.publicUrl
  }
  function formatOfferingYYYYMMDDStringToMMDDYYYY(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  }

  return {
    getAllOfferingsPaginated,
    getAllOfferingsForPartnerPaginated,
    getOfferingById,
    createOffering,
    createOfferingInTapi,
    updateOfferingInTapi,
    uploadImagesToSupabase,
    uploadOfferingImagesToSupabase,
    uploadOfferingDocumentSupabase,
    updateOffering,
    archiveOffering,
    deleteOfferingDocument,
    getOfferingFile,
    getAllApprovedOfferingsForPartner,
    getOfferingDetails,
    getOfferingDocuments,
    getOfferingDocumentsSupabase,
    getPublicFileUrl,
    updateOfferingAllowedUserGroups
  }
})