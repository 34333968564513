<template>
  <Slideover v-model="offering.show_order" header="Offering Content Order">
    <div class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full">

      <SlickDrag v-model="offering.content" />

      <ButtonSecondary text="Close" size="lg" @click="offering.show_order = false" class="w-full mt-5" />

    </div>
  </Slideover>
</template>

<script setup>
// Components
import SlickDrag from '@/components/applicationui/SlickDrag.vue'
import Slideover from '@/components/applicationui/Slideover.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';

// Model
const offering = defineModel();
</script>