import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
import { logErrorMessage } from '@/schemas/AdvancedErrorHandler.js'
import { ref, computed } from 'vue'

export const usePartnerStore = defineStore('partner', () => {
  // State (refs)
  const partner = ref(null)

  // Getters, (computed properties)
  const getLogo = computed(() => {
    const { data } = supabase.storage.from('partners').getPublicUrl(`${partner.value?.meta_name}/logo`)
    return data.publicUrl
  })
  const getBackground = computed(() => {
    const { data } = supabase.storage.from('partners').getPublicUrl(`${partner.value?.meta_name}/background`)
    return data.publicUrl
  })

  // Actions, (functions)
  async function setPartner() {
    const user_id = await getUserID()
    if (!user_id) return
    const partner_id = await getPartnerID(user_id)
    partner.value = await getPartner(partner_id)
    if (!partner.value) return
    setPartnerTheme(partner.value)
  }
  async function setPartnerById(partner_id) {
    partner.value = await getPartner(partner_id)
    if (!partner.value) return
    setPartnerTheme(partner.value)
  }
  async function setPartnerByMetaName(meta_name) {
    partner.value = await getPartnerByMetaName(meta_name)
    if (!partner.value) return
    setPartnerTheme(partner.value)
  }
  async function updatePartnerFull(partner) { //Expects full partner object
    // Create a new object to avoid mutating the original and remove meta_name, partners_protected, and credentials
    const { id, meta_name, partners_protected, credentials, ...newPartner } = partner;
    const { error } = await supabase.from('partners').update(newPartner).eq('id', id)
    if (error) { console.log(await logErrorMessage(error)); return; }
    return true
  }
  async function createPartnerFull(partner) { //Expects full partner object
    const { partners_protected, credentials, ...newPartner } = partner;
    const { data, error } = await supabase.from('partners').insert(newPartner).select()
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0].id
  }
  async function updateCredentials(partner_id, credentials) {
    const { error } = await supabase.from('credentials').update(credentials).eq('partner_id', partner_id)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function updatePartnersProtected(id, partners_protected) {
    const { error } = await supabase.from('partners_protected').update(partners_protected).eq('id', id)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function updatePartner(p) {
    const { error } = await supabase.from('partners').update({
      title: p.title,
      logo_text: p.logo_text,
      primary_color: p.primary_color,
      primary_color_light: p.primary_color_light,
      primary_color_dark: p.primary_color_dark,
      title_color: p.title_color,
      title_color_dark: p.title_color_dark,
      collect_income_data: p.collect_income_data,
      collect_employer_data: p.collect_employer_data,
      redirect_url: p.redirect_url,
      support_email: p.support_email,
      use_additional_signin_content: p.use_additional_signin_content,
      additional_signin_content: p.additional_signin_content,
      send_welcome_email: p.send_welcome_email,
      welcome_email_content: p.welcome_email_content,
      disable_signup: p.disable_signup,
      use_custom_thank_you_email: p.use_custom_thank_you_email,
      thank_you_email_content: p.thank_you_email_content,
      bcc_email: p.bcc_email,
      allowed_account_types: p.allowed_account_types
    }).eq('id', p.id)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function uploadPartnerImages(meta_name, image, fileName) {
    const { error } = await supabase.storage.from('partners').upload(`${meta_name}/${fileName}`, image, { cacheControl: '3600', upsert: true })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function getAllPartners() {
    const { data, error } = await supabase.from('partners').select('*, partners_protected(*), credentials(*)').order('created_at', { ascending: false });
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }
  function getLogoByMetaName(meta_name) {
    const { data } = supabase.storage.from('partners').getPublicUrl(`${meta_name}/logo`)
    return data.publicUrl
  }
  async function getPartnerByMetaName(meta_name) {
    const { data, error } = await supabase.from('partners').select('*, partners_protected(*)').eq('meta_name', meta_name).limit(1)
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0]
  }

  // Not Callable Functions
  function setPartnerTheme(p) {
    if (!p) return
    // Set Partner Colors
    if (p.primary_color) document.documentElement.style.setProperty('--operacolor', p.primary_color);
    if (p.primary_color_light) document.documentElement.style.setProperty('--operacolorlight', p.primary_color_light);
    if (p.primary_color_dark) document.documentElement.style.setProperty('--operacolordark', p.primary_color_dark);
    if (p.title_color) document.documentElement.style.setProperty('--titlecolor', p.title_color);
    if (p.title_color_dark) document.documentElement.style.setProperty('--titlecolordark', p.title_color_dark);
    if (p.border_color) document.documentElement.style.setProperty('--border', p.border_color);
  }
  async function getUserID() {
    const auth = await supabase.auth.getUser();
    return auth.data.user?.id
  }
  async function getPartnerID(user_id) {
    const { data, error } = await supabase.from('profiles_protected').select('partner_id').eq('id', user_id).limit(1)
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0].partner_id
  }
  async function getPartner(partner_id) {
    const { data, error } = await supabase.from('partners').select('*, partners_protected(*)').eq('id', partner_id).limit(1)
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0]
  }
  async function updateArticles(opera_articles) {
    const { error } = await supabase.from('partners').update({ opera_articles }).eq('id', partner.value.id)
    if (error) { console.log(await logErrorMessage(error)); return }
    partner.value.opera_articles = opera_articles
    return true
  }
  async function updateUserGroups(partner_id, user_groups) {
    const { error } = await supabase.from('partners').update({ user_groups }).eq('id', partner_id)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  return {
    partner,
    getLogo,
    getBackground,
    setPartner,
    setPartnerById,
    setPartnerByMetaName,
    getAllPartners,
    createPartnerFull,
    updatePartner,
    updatePartnerFull,
    updateCredentials,
    updatePartnersProtected,
    uploadPartnerImages,
    getLogoByMetaName,
    getPartnerByMetaName,
    updateArticles,
    updateUserGroups
  }
})