<template>
  <div>
    <Tooltip v-if="!copied" content="Copy">
      <ClipboardIcon @click="copy" class="w-4 h-4 text-gray-900 dark:text-white cursor-pointer" />
    </Tooltip>
    <Tooltip v-else content="Copied">
      <CheckIcon class="w-4 h-4 text-green-600 cursor-pointer" />
    </Tooltip>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Tooltip from '@/components/applicationui/Tooltip.vue'
import { ClipboardIcon, CheckIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  text: { type: String, required: true },
})

const copied = ref(false)

const copy = async () => {
  copied.value = true;
  navigator.clipboard.writeText(props.text);
  await new Promise(resolve => setTimeout(resolve, 2000));
  copied.value = false;
}

defineExpose({ copy })
</script>