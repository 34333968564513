<template>
  <div class="w-screen h-screen flex flex-col items-center justify-center gap-10">

    <div v-if="loading" class="h-52 w-52 -mt-20">
      <SpinnerJump />
    </div>

    <div v-else class="w-screen h-screen flex flex-col items-center justify-center gap-10 bg-operacolor">
      <div class="background flex flex-col gap-10 md:p-10 p-5 items-center max-w-[80vw]">
        <div class="text_primary font-bold text-2xl text-center">Thank You For Using Magic Link</div>
        <div class="text_color_tertiary">Thank you for using our secure magic link. <br />Just click continue and you are ready to go.</div>
        <img src="https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/EmailIcon.png?t=2024-05-30T21%3A42%3A03.206Z" class="h-52" alt="Email Icon">
        <button class="button_primary w-fit" @click="goToDashboard">
          Continue
          <ArrowRightIcon class="w-4 h-4" />
        </button>
      </div>
    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { usePartnerStore } from '@/stores/partner'
// Components
import SpinnerJump from '@/components/loading/SpinnerJump.vue';
// Librairies
import { ArrowRightIcon } from '@heroicons/vue/24/outline'
// Stores
const partnerStore = usePartnerStore()
// Globals
const router = useRouter()
const loading = ref(true)
// Mounted
onMounted(async () => {
  const partner_meta_name = router.currentRoute.value.query.p
  await partnerStore.setPartnerByMetaName(partner_meta_name)
  localStorage.setItem('partner_id', partnerStore.partner.id)
  loading.value = false
})
// Funcitons
const goToDashboard = () => {
  const hashed_token = router.currentRoute.value.query.h
  const verification_type = router.currentRoute.value.query.t
  const supabase_url = process.env.VUE_APP_SUPABASE_URL
  const site_url = window.location.origin
  const magicLink = `${supabase_url}/auth/v1/verify?token=${hashed_token}&type=${verification_type}&redirect_to=${site_url}/landing`
  if (!magicLink) router.push(`/login?partner=${partnerStore.partner.id}`)
  else window.location.href = magicLink
}
</script>