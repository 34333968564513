import * as Sentry from "@sentry/vue";

export const logErrorMessage = async (error) => {
  // If the error is a string, capture it then, return it
  if (typeof error === 'string') {
    Sentry.captureException(error);
    return error;
  }

  // If the error is an Edge Function, capture it then, return the error message
  if (error?.context && error?.context?.body instanceof ReadableStream) {
    try {
      const response = new Response(error.context.body);
      const text = await response.text();
      const errorMessage = JSON.parse(text);
      Sentry.captureException(errorMessage.message)
      return errorMessage.message;
    } catch (e) {
      Sentry.captureException(error);
      return error;
    }
  }

  // Handle error messages from the Supabase API
  if (error?.message) {
    Sentry.captureException(error.message);
    return error.message;
  }

  // Handle other error messages
  Sentry.captureException(error);
  return error;
};