<template>
  <Slideover v-model="offering.show_payment" header="Offering Payment Settings">
    <div class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full">

      <div class="flex flex-col gap-5">
        <!-- Only Accept Custody Payments -->
        <Switch v-if="partnerStore.partner.allow_custody_accounts" v-model="offering.is_custody_only" label="Only Accept Custody Payments"
          description="Will this offering only accept payments via custody?" />

        <!-- Check Instructions for Custody -->
        <div v-if="offering.is_custody_only">
          <span class="font-medium leading-6 text-gray-900 dark:text-neutral-300 text-base">Check Instructions for Funding Account</span>
          <div class="flex flex-col gap-5 p-5 mt-2 rounded-lg shadow bg-white dark:bg-neutral-900 dark:shadow-none">
            <!-- UPS and Fedex -->
            <TextareaCustom label="Fedex or UPS" :rows="2" v-model="offering.custody_fedex_instructions" :required="true" class="flex-1" />
            <!-- Regular Mail -->
            <TextareaCustom label="Regular Mail" :rows="2" v-model="offering.custody_mail_instructions" :required="true" class="flex-1" />
          </div>
        </div>

        <!-- Wire Instruction for Custody -->
        <div v-if="offering.is_custody_only">
          <span class="font-medium leading-6 text-gray-900 dark:text-neutral-300 text-base">Wire Instructions for Funding Account</span>
          <div class="flex flex-col gap-5 p-5 mt-2 rounded-lg shadow bg-white dark:bg-neutral-900 dark:shadow-none">
            <div class="flex flex-wrap gap-5">
              <!-- Receiving Bank -->
              <Input id="custody_bank" label="Receiving Bank" placeholder="Chase Bank" v-model="offering.custody_bank_instructions" :required="true" class="flex-1 min-w-60" />
              <!-- Account Number -->
              <Input id="custody_account_number" label="Account Number" placeholder="123456789" v-model="offering.custody_account_number_instructions" :required="true" class="flex-1 min-w-60" />
            </div>

            <div class="flex gap-5">
              <!-- Routing Number -->
              <Input id=" custody_routing_number" label="Routing Number" placeholder="123456789" v-model="offering.custody_routing_number_instructions" :required="true" class="flex-1 min-w-60" />
              <!-- Reference -->
              <Input id="custody_reference" label="Reference" placeholder="NC-[YOUR PARTNER NAME] AND 123456789" v-model="offering.custody_reference_instructions" :required="true"
                class="flex-1 min-w-60" />
            </div>

            <!-- Address -->
            <TextareaCustom label="Address" :rows="2" v-model="offering.custody_address_instructions" :required="true" class="flex-1" />
          </div>
        </div>

        <!-- Accept Payment Methods -->
        <Switch v-model="offering.accept_ach" label="Accept ACH Payment" description="Will this offering accept payment via ACH?" />

        <!-- Accept Check Payment -->
        <Switch v-model="offering.accept_cc" label="Accept Credit Card" description="Will this offering accept payment via Credit Card?" />

        <!-- Accept Wire Payment -->
        <Switch v-model="offering.accept_wire" label="Accept Wire Payment" description="Will this offering accept payment via wire transfer?" />


        <!-- Wire Instruction for Custody -->
        <div v-if="offering.accept_wire">
          <span class="font-medium leading-6 text-gray-900 dark:text-neutral-300 text-base">Wire Instructions</span>
          <div class="flex flex-col gap-5 p-5 mt-2 rounded-lg shadow bg-white dark:bg-neutral-900 dark:shadow-none">
            <div class="flex flex-wrap gap-5">
              <!-- Receiving Bank -->
              <Input id="bank" label="Receiving Bank" placeholder="Chase Bank" v-model="offering.bank_instructions" :required="true" class="flex-1 min-w-60" />
              <!-- Account Number -->
              <Input id="account_number" label="Account Number" placeholder="123456789" v-model="offering.account_number_instructions" :required="true" class="flex-1 min-w-60" />
            </div>

            <div class="flex gap-5">
              <!-- Routing Number -->
              <Input id="routing_number" label="Routing Number" placeholder="123456789" v-model="offering.routing_number_instructions" :required="true" class="flex-1 min-w-60" />
              <!-- Reference -->
              <Input id="reference" label="Reference" placeholder="NC-[YOUR PARTNER NAME] AND 123456789" v-model="offering.reference_instructions" :required="true" class="flex-1 min-w-60" />
            </div>

            <!-- Address -->
            <TextareaCustom label="Address" :rows="2" v-model="offering.address_instructions" :required="true" class="flex-1" />
          </div>
        </div>

        <!-- Accept Check Payment -->
        <Switch v-model="offering.accept_check" label="Accept Check Payment" description="Will this offering accept payment via check?" />

        <!-- Check Instructions -->
        <div v-if="offering.accept_check">
          <span class="font-medium leading-6 text-gray-900 dark:text-neutral-300 text-base">Check Instructions</span>
          <div class="flex flex-col gap-5 p-5 mt-2 rounded-lg shadow bg-white dark:bg-neutral-900 dark:shadow-none">
            <!-- UPS and Fedex -->
            <TextareaCustom label="Fedex or UPS" :rows="2" v-model="offering.fedex_instructions" :required="true" class="flex-1" />
            <!-- Regular Mail -->
            <TextareaCustom label="Regular Mail" :rows="2" v-model="offering.mail_instructions" :required="true" />
          </div>
        </div>

      </div>

      <!-- Buttons -->
      <ButtonSecondary text="Close" size="lg" @click="offering.show_payment = false" class="w-full mt-10" />
    </div>
  </Slideover>
</template>

<script setup>
import { watch } from 'vue';

// Components
import Slideover from '@/components/applicationui/Slideover.vue';
import Switch from '@/components/applicationui/Switch.vue'
import TextareaCustom from '@/components/applicationui/TextareaCustom.vue';
import Input from '@/components/applicationui/Input.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';

// Stores
import { usePartnerStore } from '@/stores/partner';
const partnerStore = usePartnerStore();

// Model
const offering = defineModel();

// Watchers
watch(() => offering.value.is_custody_only, () => {
  if (offering.value.is_custody_only) {
    offering.value.accept_ach = false
    offering.value.accept_cc = false
    offering.value.accept_wire = false
    offering.value.accept_check = false
  }
}, { deep: true })

watch(() => offering.value.accept_ach, () => {
  if (offering.value.accept_ach) offering.value.is_custody_only = false
}, { deep: true })

watch(() => offering.value.accept_cc, () => {
  if (offering.value.accept_cc) offering.value.is_custody_only = false
}, { deep: true })

watch(() => offering.value.accept_wire, () => {
  if (offering.value.accept_wire) offering.value.is_custody_only = false
}, { deep: true })

watch(() => offering.value.accept_check, () => {
  if (offering.value.accept_check) offering.value.is_custody_only = false
}, { deep: true })
</script>