<template>
  <Slideover v-model="offering.show_visibility" header="Offering Visibility & Status">
    <!-- Content -->
    <div class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full">
      <div class="flex-1">

        <!-- Status -->
        <SafeSelect v-model="offering.status" label="Status" :items="['approved', 'pending', 'disapproved']" class="min-w-52 max-w-72" />

        <!-- Visibility -->
        <div class="flex gap-5 flex-wrap items-end pt-5 mt-6 border-t border-dashed border-gray-300 dark:border-neutral-700">
          <SafeSelect v-model="offering.visibility" label="Visibility" :items="['public', 'private', 'advisor', 'accredited', 'custom']" :disabled="offering.use_regcf_protocols"
            class="min-w-52 max-w-72" />
        </div>

        <!-- Visibility Custom -->
        <div v-if="offering.visibility == 'custom'" class="mt-6">
          <div class="flex flex-col gap-10">
            <CreateUserGroup :user_groups="offering?.partners?.user_groups" :partner_id="offering?.partners?.id" />
            <div>
              <fieldset>
                <legend class="text-base font-semibold leading-6 text-gray-900 dark:text-white flex gap-1 items-center">
                  Allowed Users Groups
                  <Tooltip content="Selected user groups will have access to this offering">
                    <QuestionMarkCircleIcon class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight" />
                  </Tooltip>
                </legend>
                <div v-if="offering?.partners?.user_groups?.length > 0"
                  class="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 dark:border-neutral-700 dark:divide-neutral-700 max-h-96 overflow-y-scroll">
                  <div v-for="(user_group, index) in offering?.partners?.user_groups" :key="index" class="relative flex items-start py-4">
                    <Checkbox :label="user_group" v-model="checkedUserGroups[index]" class="pl-1" />
                  </div>
                </div>
                <div v-else class="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 dark:border-neutral-700 dark:divide-neutral-700">
                  <div class="relative flex justify-center py-4">
                    <p class="text-sm text-gray-500 dark:text-neutral-400">No user groups, please create one</p>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <div class="flex justify-end gap-5">
        <ButtonSecondary text="Close" size="lg" @click="offering.show_visibility = false" class="w-full mt-5" />
      </div>

    </div>
    <!-- Content -->
  </Slideover>
</template>

<script setup>
// Essentials
import { ref, onMounted, watch } from 'vue';

// Stores
// import { useOfferingsStore } from '@/stores/offerings';
// const offeringsStore = useOfferingsStore()

// Components
import CreateUserGroup from '@/components/ui/CreateUserGroup.vue';
import Slideover from '@/components/applicationui/Slideover.vue';
import SelectMenu from '@/components/applicationui/SelectMenu.vue';
import SafeSelect from '@/components/inputs/safeSelect.vue';
import Checkbox from '@/components/applicationui/Checkbox.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';
import Tooltip from '@/components/applicationui/Tooltip.vue';

// Libraries
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';

// Model
const offering = defineModel()

// Custom Visibility
const checkedUserGroups = ref(Array(offering.value?.partners?.user_groups?.length).fill(false))

// Watcher to update allowed_user_groups
watch(checkedUserGroups, (newVal) => {
  offering.value.allowed_user_groups = offering.value?.partners?.user_groups?.filter((_, index) => newVal[index]);
}, { deep: true });

onMounted(() => {
  if (offering.value?.allowed_user_groups) {
    offering.value?.allowed_user_groups.forEach(group => {
      const index = offering.value?.partners?.user_groups?.indexOf(group);
      if (index !== -1) {
        checkedUserGroups.value[index] = true;
      }
    });
  }
})
</script>

