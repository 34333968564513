<template>

  <Slideover v-model="offering.show_forum_manager" header="Forum Manager">
    <!-- Content -->
    <div class="relative mt-6 flex-1 flex flex-col px-4 sm:px-6">

      <div>
        <div class="mt-5 border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
          <!-- Tab Header -->
          <h3 class="primary_header">Comments</h3>
        </div>
      </div>

      <div class="flex gap-10 flex-wrap">
        <ForumFeed :offering_id="offering.id" />
      </div>

    </div>
    <!-- Content -->
  </Slideover>

</template>

<script setup>
// Components
import Slideover from '@/components/applicationui/Slideover.vue';
import ForumFeed from '@/components/widgets/ForumFeed.vue';

// Model
const offering = defineModel()
</script>
