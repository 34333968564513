<template>
  <div>
    <div v-if="props.label || !required" class="flex justify-between mb-2">
      <label v-if="props.label" :for="props.id" class="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
        {{ props.label }}
        <Tooltip v-if="props.tooltip" :content="props.tooltip">
          <QuestionMarkCircleIcon class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight" />
        </Tooltip>
      </label>
      <span v-if="!required" class="text-sm leading-6 text-gray-400 dark:text-neutral-500" :id="`${id}-optional`">Optional</span>
    </div>
    <div class="relative">
      <input v-model="text" :type="props.type" :name="props.id" :id="props.id" :placeholder="props.placeholder" :autocomplete="autocomplete" :required="required" :disabled="props.disabled"
        :aria-describedby="`${id}-optional`" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 bg-white placeholder:text-gray-400 
        focus:ring-2 focus:ring-inset focus:ring-operacolor
        dark:text-neutral-100 dark:ring-neutral-700 dark:bg-neutral-800 dark:focus:ring-operacolorlight dark:placeholder:text-neutral-500
        disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200
        dark:disabled:cursor-not-allowed dark:disabled:bg-neutral-700 dark:disabled:text-neutral-400" />
      <!-- <div v-if="props.eye" class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" @click="toggleInputType">
        <IconEyeOff v-if="inputType == 'password'" class="h-5 w-5" aria-hidden="true" />
        <IconEye v-else class="h-5 w-5" aria-hidden="true" />
      </div> -->
    </div>
  </div>
</template>

<script setup>
import Tooltip from '@/components/applicationui/Tooltip.vue';
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';
// import { ref } from 'vue'

// Model
const text = defineModel()

// Props
const props = defineProps({
  id: { type: String, required: true, default: 'text' },
  label: { type: String, required: false, default: null },
  type: { type: String, required: false, default: 'text' },
  placeholder: { type: String, required: false, default: '' },
  disabled: { type: Boolean, required: false, default: false },
  autocomplete: { type: String, required: false, default: 'off' },
  required: { type: Boolean, required: false, default: false },
  tooltip: { type: String, required: false, default: null },
  // eye: { type: Boolean, required: false, default: false }
})

// Toggle input type
// const inputType = ref(props.type)
// const toggleInputType = () => {
//   inputType.value = inputType.value === props.type ? 'text' : 'password'
// }
</script>