<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 dark:bg-neutral-500 dark:bg-opacity-25 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col overflow-y-scroll bg-gray-50 dark:bg-neutral-950 py-6 shadow-xl">

                  <!-- Header -->
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                        <h3 class="text-xl font-bold text-gray-900 sm:text-2xl dark:text-white">Opera Articles</h3>
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md text-gray-400 hover:text-gray-500 dark:text-neutral-400 dark:hover:text-neutral-300 focus:outline-none" @click="open = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Header -->

                  <!-- Content -->
                  <div class="relative mt-6 flex-1 flex flex-col px-4 sm:px-6">

                    <!-- Header -->
                    <div>
                      <div class="mt-5 border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
                        <!-- Tab Header -->
                        <h3 class="primary_header">Curated Articles</h3>

                        <div class="flex items-center gap-4 flex-wrap">
                          <!-- Search -->
                          <div class="flex items-center gap-2">
                            <div>
                              <div class="relative rounded-md">
                                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                  <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
                                </div>
                                <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <!-- Opera Articles -->
                    <div class="flex gap-10 flex-wrap">
                      <div v-for="a in filteredArticles" :key="a.id" class="relative group">
                        <ArticleCard :article="a" :preview="true" />
                        <div @click="handleAdd(a.id)"
                          class="absolute top-2 right-2 bg-operacolor hover:bg-operacolorlight shadow text-white rounded-md px-2 py-1 group-hover:visible invisible flex gap-1 items-center cursor-pointer">
                          Add
                          <PlusIcon class="w-4 h-4" />
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- Content -->

                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, computed } from 'vue'
import { useUserStore } from '@/stores/user'
import { usePartnerStore } from '@/stores/partner'

// Components
import ArticleCard from '@/components/cards/ArticleCard.vue'

// Libraries
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/vue/24/solid'

// Model
const open = defineModel()

// Props
const props = defineProps({
  articles: { type: Array, required: true, default: [] }
})

// Emits
const emit = defineEmits(['article-added'])

// Stores
const userStore = useUserStore()
const partnerStore = usePartnerStore()

// Mounted

// Search
const query = ref('');
const filteredArticles = computed(() => {
  if (!props.articles) return []
  return query.value === '' ? props.articles :
    props.articles.filter((article) => {
      if (article?.title?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (article?.description?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (article?.id?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (article?.author?.toLowerCase().includes(query.value.toLowerCase())) return true;
    })
});

// Add Article to Partner
const handleAdd = async (id) => {
  // Get the partners articles
  let opera_articles = partnerStore.partner.opera_articles
  if (!opera_articles) opera_articles = []
  if (opera_articles.includes(id)) return
  opera_articles.push(id)

  partnerStore.updateArticles(opera_articles)

  emit('article-added')
}
</script>
