<template>
  <div>
    <div v-if="props.label" class="select_menu_label">{{ props.label }}</div>
    <!-- Has an id and is therefore objects -->
    <Listbox v-if="selected?.id" as="div" v-model="selected">
      <div class="relative">
        <ListboxButton
          class="relative w-full cursor-pointer rounded-md bg-white dark:bg-neutral-800 dark:text-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-0 focus:outline-none focus:ring-2 focus:ring-operacolor sm:text-sm sm:leading-6">
          <span class="block truncate">{{ selected.label ?? selected.name ?? `${selected.first_name} ${selected.last_name}` }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-neutral-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="item in props.items" :key="item.id" :value="item" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-operacolor text-white' : 'text-gray-900 dark:text-neutral-200', 'relative cursor-pointer select-none py-2 pl-3 pr-9']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ item.label ?? item.name ?? `${item.first_name} ${item.last_name}` }}</span>

                <span v-if="selected" :class="[active ? 'text-white' : 'text-operacolor', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>

    <!-- No id's and is therefore strings -->
    <Listbox v-else as="div" v-model="selected" :disabled="props.disabled">
      <div class="relative">
        <ListboxButton
          :class="[props.disabled ? 'bg-gray-100 opacity-75 cursor-not-allowed ' : 'bg-white dark:bg-neutral-800 cursor-pointer ', 'relative w-full rounded-md dark:text-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-0 focus:outline-none focus:ring-2 focus:ring-operacolor sm:text-sm sm:leading-6']">
          <span class="block truncate">{{ capitalizeFirstLetter(getStateName(selected)) }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-neutral-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            :class="[props.inverted ? '-top-64' : '']">
            <ListboxOption as="template" v-for="item in props.items" :key="item" :value="item" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-operacolor text-white' : 'text-gray-900 dark:text-neutral-200', 'relative cursor-pointer select-none py-2 pl-3 pr-9']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ capitalizeFirstLetter(getStateName(item)) }}</span>

                <span v-if="selected" :class="[active ? 'text-white' : 'text-operacolor', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script setup>
// Essentials
import { onMounted } from 'vue';
// Libraries
import { capitalizeFirstLetter, getStateName } from '@/helper/helper';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
// Props
const props = defineProps({
  items: { type: Array, required: true, default: null }, //This component expects that the array will have objects with the following structure: { id: number, label: string }
  inverted: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  label: { type: String, required: false, default: null },
  // special_type: { type: String, required: false, default: '' },
})
// Models
const selected = defineModel()
// Mounted
onMounted(() => {
  if (props?.items && props?.items.length > 0 && !selected.value) selected.value = props?.items[0]
})
</script>