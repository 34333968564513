<template>
  <div>
    <label for="title_color" class="!flex items-center gap-2 input_label">
      {{ props.label }}
      <Tooltip v-if="props.tooltip" :content="props.tooltip">
        <QuestionMarkCircleIcon class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight" />
      </Tooltip>
    </label>
    <div class="mt-2">
      <input ref="input_ref" type="color" class="color_input_custom" placeholder="#00000" v-model="color" :disabled="props.disabled" :required="props.required" :name="props.id" :id="props.id" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Tooltip from '@/components/applicationui/Tooltip.vue';
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  id: { type: String, required: true, default: null },
  label: { type: String, required: false, default: 'Color' },
  disabled: { type: Boolean, required: false, default: false },
  tooltip: { type: String, required: false, default: null },
})

const color = defineModel()

// Set error message
const input_ref = ref(null)
const setErrorMessage = (message = 'This field is required') => {
  input_ref.value.setCustomValidity(message)
  input_ref.value.reportValidity()
}
defineExpose({ setErrorMessage })
</script>

<style scoped>
.color_input_custom {
  @apply p-1 h-10 w-14 bg-white dark:bg-transparent dark:border-neutral-700 border border-gray-200 cursor-pointer rounded-lg
}
</style>
