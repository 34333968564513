<template>
  <div class="mb-24">
    <!-- Popups -->
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />

    <!-- Investor Flow -->
    <!-- investmentFlowType -->
    <AlphaFlow v-if="showFlow" v-model="showFlow" :need="need" :trade="trade" :offering="offering" :custody_account="selectedAccount" @completed="showFlow = false" @closed="showFlow = false"
      @error="handleError" />
    <!-- TODO insufficientFunds, error -->

    <!-- Popups -->

    <!-- Loading -->
    <div v-if="loading" class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Contemt -->
    <div v-else class="relative dark:text-neutral-300">
      <!-- Back Button -->
      <router-link v-if="partnerStore.partner.partners_protected?.partner_type != 'dib' && route.name != 'edit-offering'" to="/dashboard/investing"
        class="absolute flex items-center gap-1 py-1 pl-2 pr-3 rounded-full bg-white dark:bg-neutral-900 text-black dark:text-neutral-300 top-5 left-5 ring-1 ring-white dark:ring-neutral-800 w-fit hover:bg-transparent hover:text-white dark:hover:text-white">
        <ChevronLeftIcon class="w-4 h-4" />
        Back
      </router-link>
      <!-- Banner Image -->
      <img :class="[(route.name == 'edit-offering') ? '' : '-mt-10', 'object-cover w-[calc(100%_+_120px)] bg-gray-200 h-52 shadow']" :src="offeringBanner" />

      <div class="flex mx-10 gap-x-10 flex-wrap">
        <div class="flex flex-col w-11/12 gap-5 sm:mx-5 md:mx-auto md:w-fit">

          <!-- Offering Logo -->
          <div class="-mt-12 overflow-hidden bg-white dark:bg-neutral-900 rounded-full w-fit ring-2 ring-border dark:ring-neutral-800">
            <img :src="offeringLogo" class="object-contain w-24 h-24" />
          </div>

          <!-- Name & Slogan -->
          <div class="flex flex-col gap-2">
            <div class="max-w-3xl text-3xl font-bold">{{ offering.name }}</div>
            <div class="w-full max-w-3xl text-xl font-bold text-gray-500 dark:text-neutral-500">{{ offering.slogan }}</div>
          </div>

          <!-- Description -->
          <div class="flex flex-col gap-1">
            <div class="font-semibold text-gray-400">Description</div>
            <div class="w-full max-w-3xl overflow-hidden text-sm">{{ offering.description }}</div>
          </div>

          <!-- Factright Report -->
          <a v-if="offering.show_factright_report" href="https://www.factright.com/" target="_blank" class="button_secondary !text-green-500 w-fit">
            <img src="https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/factrightLogo.png?t=2024-06-05T05%3A23%3A22.371Z" alt="Factright Logo" class="h-6">
            FactRight Report
          </a>

          <!-- Navigation Bar -->
          <div class="flex items-center justify-between w-full max-w-3xl gap-4 p-1 overflow-x-scroll bg-gray-200 rounded-xl sm:overflow-x-hidden dark:bg-neutral-800 my-4">
            <div v-for="section in sections" @click="setSection(section)" class="flex items-center justify-center rounded-lg cursor-pointer grow"
              :class="[(visibleSection == section) ? 'bg-white dark:bg-neutral-700 font-semibold dark:text-white text-black' : '']">
              <div class="flex items-center gap-1 px-2 py-1">
                <ListBulletIcon v-if="section == 'overview'" class="w-5 h-5" />
                <Squares2X2Icon v-else-if="section == 'scorecards'" class="w-5 h-5" />
                <DocumentIcon v-else-if="section == 'documents'" class="w-5 h-5" />
                <MegaphoneIcon v-else-if="section == 'forum'" class="w-5 h-5" />
                <span class="whitespace-nowarp font-medium">{{ capitalizeFirstLetter(section) }}</span>
              </div>
            </div>
          </div>

          <!-- Content -->
          <div class="max-w-3xl w-full p-1">
            <div v-if="visibleSection == 'overview'" class="flex flex-col gap-8">
              <!-- Adjustable Content Start -->
              <div v-for="content in offering.content" :key="content.id">

                <!-- Links Section -->
                <div v-if="content.type == 'links'" class="flex flex-wrap items-center space-x-6">
                  <a v-for="link in offering.links" :key="link.id" :href="link.url" target="_blank" class="text-blue-500 hover:text-blue-600 flex gap-1">
                    <LinkIcon v-if="link.type == 'website'" class="w-6 h-6" />
                    <svg v-else-if="link.type == 'twitter'" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                    <svg v-else-if="link.type == 'facebook'" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                      <path fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd" />
                    </svg>
                    <svg v-else-if="link.type == 'instagram'" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                      <path fill-rule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clip-rule="evenodd" />
                    </svg>
                    <svg v-else-if="link.type == 'linkedin'" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        fill-rule="evenodd" clip-rule="evenodd" />
                    </svg>
                    <span class="w-fit underline font-medium">{{ link.name }}</span>
                  </a>
                </div>

                <!-- Overview Key Values Section -->
                <div v-else-if="content.type == 'key_values'">
                  <div class="bg-white dark:bg-neutral-900 shadow dark:shadow-none !rounded-xl w-full">
                    <dl class="divide-y divide-gray-100 dark:divide-neutral-800">
                      <div v-for="key_value in offering.key_values">

                        <!-- Dynamic Key Values -->
                        <div v-if="key_value.type == 'curated'" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <!-- Curated Cases -->
                          <dt v-if="key_value.id == 'start-date' && offering.offering_type == 'Fund'" class="tertiary_subheader dark:text-white">Inception Date</dt>
                          <dt v-else class="tertiary_subheader dark:text-white">{{ key_value.name }}</dt>

                          <dd v-if="key_value.id == 'security-type'" :class="[getBadgeClass(offering.offering_type), 'w-fit mt-1 text sm:col-span-2 sm:mt-0']">{{ capitalizeFirstLetter(offering.offering_type) }}</dd>
                          <dd v-else-if="key_value.id == 'minimum-investment'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(offering?.minimum) }}</dd>
                          <dd v-else-if="key_value.id == 'maximum-investment'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(offering?.maximum) }}</dd>
                          <dd v-else-if="key_value.id == 'raise-goal'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(offering?.target_amount) }}</dd>
                          <dd v-else-if="key_value.id == 'unit-price'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(offering?.unit_price) }}</dd>
                          <dd v-else-if="key_value.id == 'pending-amount'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(offeringDetails?.pendingAmount) }}</dd>
                          <dd v-else-if="key_value.id == 'funded-amount'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(offeringDetails?.fundedAmount) }}</dd>
                          <dd v-else-if="key_value.id == 'start-date'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatDate(offering?.start_date) }}</dd>
                          <dd v-else-if="key_value.id == 'end-date'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatDate(offering?.end_date) }}</dd>
                          <dd v-else class="w-fit mt-1 text sm:col-span-2 sm:mt-0">Not Available</dd>

                        </div>
                        <!-- Normal Cases -->
                        <div v-else class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="tertiary_subheader dark:text-white">{{ key_value.name }}</dt>
                          <dd :class="[(key_value.type.includes('badge')) ? getBadgeClass(key_value.type) + ' w-fit' : 'mt-1 text sm:col-span-2 sm:mt-0']">{{ key_value.value }}</dd>
                        </div>
                      </div>

                      <!-- Opera Score -->
                      <div
                        v-if="(partnerStore.partner.partners_protected.show_scores && offering.show_score) || (userStore.user.role != 'investor' && partnerStore.partner.show_scores_for_advisors && offering.show_score)"
                        class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="tertiary_subheader dark:text-white">{{ partnerStore.partner.partner_scores ? `${partnerStore.partner.title} Score` : 'Opera Score' }}</dt>
                        <dd class="mt-1 text sm:col-span-2 sm:mt-0">
                          <div v-if="offering.score && offering.score != 'Not yet available'" class="flex items-center gap-1">
                            <span class="mr-1 text-xs text-gray-400">({{ offering.score }})</span>
                            <OperaScore :score="offering.score" />
                          </div>
                          <span v-else class="text-operacolor">{{ offering.score }}</span>
                        </dd>
                      </div>

                    </dl>
                  </div>
                </div>

                <!-- Content (Text & Paragraph) Section -->
                <div v-else-if="content.type == 'content'">
                  <div v-if="content.header" class="secondary_header mb-3">{{ content.header }}</div>
                  <p class="whitespace-pre-line leading-8 text-gray-700 dark:text-neutral-400">{{ content.text }}</p>
                </div>

                <!-- Image Section -->
                <div v-else-if="content.type == 'image'">
                  <img :src="offeringsStore.getPublicFileUrl(offering.id, content.name)" alt="Content Image" class="rounded-md">
                </div>

              </div>
              <!-- Adjustable Content End -->
            </div>

            <!-- Scorecards Section -->
            <div v-else-if="visibleSection == 'scorecards'" class="flex flex-wrap gap-5 md:flex-nowrap">

              <div class="flex flex-col w-full gap-4 md:w-1/2">
                <ScoreExpandable title="Financials" :score="offering.financials" :explanation="offering.financials_explanation" />
                <ScoreExpandable title="Management" :score="offering.management" :explanation="offering.management_explanation" />
                <ScoreExpandable title="Offering Documents" :score="offering.documents" :explanation="offering.documents_explanation" />
                <ScoreExpandable title="Fund Longevity" :score="offering.longevity" :explanation="offering.longevity_explanation" />
              </div>

              <div class="flex flex-col w-full gap-4 md:w-1/2">
                <ScoreExpandable title="Fees & Expenses" :score="offering.fee_score" :explanation="offering.fee_explanation" />
                <ScoreExpandable title="Assets Under Management" :score="offering.aum" :explanation="offering.aum_explanation" />
                <ScoreExpandable title="Liquidity" :score="offering.liquidity" :explanation="offering.liquidity_explanation" />
              </div>

            </div>

            <!-- Forum Section -->
            <div v-else-if="visibleSection == 'forum'" class="flex flex-wrap gap-5 md:flex-nowrap">

              <ForumFeed :offering_id="offering.id" />

            </div>

            <!-- Documents Section -->
            <div v-else>

              <!-- Loading Documents Section -->
              <div v-if="isLoadingOfferingDocs" class="flex flex-col gap-4">
                <div v-for="index in 3" :key="index" class="flex items-center justify-between h-10 gap-10 bg-gray-200 rounded-lg shadow-sm dark:bg-neutral-700 animate-pulse"></div>
              </div>

              <!-- Documents Section Empty State -->
              <div v-else-if="docs.length <= 0" class="p-4 flex justify-center secondary_header rounded-b-lg dark:bg-neutral-900">No documents found</div>

              <!-- Documents Table -->
              <div v-else class="shadow ring-1 ring-black ring-opacity-5 background relative">
                <div
                  class="text-base font-semibold leading-6 text_color_primary p-4 border-gray-300 border-b-[1px] dark:border-b-[1px] dark:border-neutral-700 bg-gray-100 dark:bg-neutral-800 rounded-t-lg">
                  <!-- Table Header -->
                  <div class="flex items-center justify-between flex-wrap gap-y-3">
                    <span class="flex items-center flex-wrap gap-2">Offering Documents</span>
                  </div>
                </div>
                <!-- Table Rows -->
                <ul role="list" class="divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900 max-h-72 overflow-y-scroll rounded-b-lg bg-white">
                  <li v-for="doc in docs" :key="doc.id" class="flex justify-between sm:gap-x-6 py-3 mx-3 first:pt-3 last:pb-3">
                    <div class="flex min-w-0 gap-x-4 items-center">
                      <div class="bg-white ring-1 ring-gray-300 p-1.5 rounded-lg text-xs dark:bg-neutral-800 dark:ring-neutral-700">
                        <DocumentTextIcon class="text-operacolor w-5 h-5" />
                      </div>
                      <div class="min-w-0 flex items-center w-40">
                        <p class="text-sm font-semibold leading-6 text-gray-900 dark:text-neutral-300 truncate">{{ doc.name }}</p>
                      </div>
                    </div>
                    <div class="shrink-0 flex flex-col items-start justify-center w-40">
                      <div class="text-sm text-gray-500 dark:text-neutral-400">{{ formatDate(doc.updated_at) }}</div>
                    </div>
                    <div class="flex gap-5 items-center">
                      <a :href="offeringsStore.getPublicFileUrl(offering.id, `documents/${doc.name}`)" target="_blank"
                        class="dark:text-[rgb(102,183,237)] text-[rgba(1,108,178)] px-3.5 py-1.5 rounded-md bg-[rgba(1,108,178,0.1)] flex items-center gap-2 cursor-pointer hover:bg-[rgba(1,108,178)] hover:text-white transition ease-in-out duration-700">
                        Download
                        <ArrowDownTrayIcon class="w-4 h-4" />
                      </a>
                    </div>
                  </li>
                </ul>

              </div>

            </div>

          </div>
        </div>

        <!-- Checkout Form -->
        <div class="w-[500px] md:relative justify-self-center mx-auto">
          <div class="md:sticky top-32 mt-16 flex flex-col gap-8 p-6 w-full sm:w-fit sm:mx-auto divide-y rounded-lg divide-white/50 divide-dashed h-fit"
            :style="`background: linear-gradient(to right, ${offering.gradient_start_color}, ${offering.gradient_end_color})`">
            <div class="flex flex-col gap-6">
              <!-- Title -->
              <span class="text-xl font-bold text-white">Invest Form</span>

              <!-- Account Select -->
              <div v-if="partnerStore.partner.allow_custody_accounts && accountsStore?.myAccounts?.length > 0">
                <div v-if="loadingAccounts" class="h-10 bg-gray-200 rounded-md shadow-sm dark:bg-neutral-700 sm:w-96 w-full animate-pulse"></div>
                <div v-else-if="accountsStore.myAccounts.length > 0" class="sm:w-96 w-full">
                  <div class="block text-sm font-medium leading-6 text-white mb-2">Custody Account</div>
                  <SelectMenu v-model="selectedAccount" :items="accountsStore.myAccounts" />
                </div>
              </div>

              <!-- Shares -->
              <div class="sm:w-96 w-full">
                <label for="numShares" class="block text-sm font-medium leading-6 text-white">{{ getType(offering.offering_type) }}</label>
                <div class="input_wrapper">
                  <input v-model="numShares" type="text" name="numShares" id="numShares"
                    class="block w-full rounded-md border-0 bg-[rgba(255,255,255,.2)] py-1.5 text-gray-200 shadow-sm placeholder:text-gray-200 focus:ring-1 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    :placeholder="getPlaceholderValue(offering.offering_type)" required="" />
                </div>
                <div class="text-sm text-gray-200 pt-2 -mb-2" v-if="offering.minimum > 1">The minimum investment is ({{ formatMoney(offering.minimum) }})</div>
              </div>
            </div>

            <div class="flex flex-col gap-8">
              <!-- Total -->
              <div class="flex items-center justify-between pt-6 sm:w-96 w-full">
                <div class="font-semibold text-white text-md">Total Investment<br> Amount</div>
                <div class="text-lg font-bold text-white w-fit">{{ formatMoney(investmentTotal) }}</div>
              </div>

              <!-- Invest Button -->
              <div @click="investPopup()" :class="buttonClass" class="justify-center">
                <LoadGifButtonDark v-if="loadingInvestPopup" class="h-[20px]" />
                <div v-else class="flex gap-1">
                  <div v-if="buttonClass == 'button_invest'">Invest</div>
                  <div v-else>Enter Your Investment First</div>
                  <CurrencyDollarIcon class="w-5 h-5" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted, watch, h, markRaw, defineComponent } from 'vue'
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user'
import { usePartnerStore } from '@/stores/partner'
import { useAccountsStore } from '@/stores/accounts'
import { useOfferingsStore } from '@/stores/offerings'
// Components
import LoadGifButtonDark from '@/components/loading/LoadGifButtonDark.vue';
import ScoreExpandable from '@/components/ui/ScoreExpandable.vue'
import SelectMenu from '@/components/applicationui/SelectMenu.vue'
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
import OperaScore from '@/components/ui/OperaScore.vue'
import AlphaFlow from '@/components/flows/AlphaFlow.vue'
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import ForumFeed from '@/components/widgets/ForumFeed.vue'
// Libraries
import { getPlaceholderValue, formatDate, getType, formatMoney, capitalizeFirstLetter, getBadgeClass } from '@/helper/helper'
import { DocumentTextIcon } from '@heroicons/vue/24/solid';
import { ChevronLeftIcon, ListBulletIcon, Squares2X2Icon, DocumentIcon, CurrencyDollarIcon, ArrowDownTrayIcon, GlobeAltIcon, LinkIcon, MegaphoneIcon } from '@heroicons/vue/24/outline'
// Stores
const userStore = useUserStore()
const partnerStore = usePartnerStore()
const accountsStore = useAccountsStore()
const offeringsStore = useOfferingsStore()
const route = useRoute()
// Globals
const need = ref('create-direct-investment')
const selectedAccount = ref(null)
const offering = ref({})
const offeringDetails = ref({})
const docs = ref([])
const trade = ref({})
const numShares = ref('')
const investmentTotal = ref('')
const sections = ref(['overview', 'documents'])
// images
const offeringLogo = ref('')
const offeringBanner = ref('')
// css
const socials = ref([])
const overviewClass = ref('bg-white text-operacolor')
const scorecardsClass = ref('')
const documentsClass = ref('')
const forumClass = ref('')
const buttonClass = ref('button_disabled')
const visibleSection = ref('overview')
// loading
const loading = ref(true)
const loadingAccounts = ref(true)
const isLoadingOfferingDetails = ref(true)
const isLoadingOfferingDocs = ref(true)
const loadingInvestPopup = ref(false)
// bools
const showFlow = ref(false)
// notifications
const notification = ref(null)
const notificationType = ref('success')
const notificationTitle = ref('Success')
const notificationText = ref('')

// Optional Props for Offering Preview from Offering Edit Page
const props = defineProps({
  offering: { type: Object, required: false, default: null }
})
// Optional Props for Offering Preview from Offering Edit Page

// Mounted
onMounted(async () => {
  // Set accounts
  setAccounts()

  // Set Offering
  if (!props.offering) await setOffering(route.params.id)
  else offering.value = props.offering

  // Set Sections
  if (partnerStore.partner.partner_scores && offering.show_score) sections.value.push('scorecards')
  if (offering.value.show_forum) sections.value.push('forum')

  // Load in offering images
  offeringLogo.value = await offeringsStore.getOfferingFile(offering.value.id, offering.value.tapi_offering_id, 'logo')
  offeringBanner.value = await offeringsStore.getOfferingFile(offering.value.id, offering.value.tapi_offering_id, 'banner')

  // Set social links
  setSocials(offering.value)

  // Ready
  loading.value = false

  // lazy loaded
  setOfferingDetails(offering.value.tapi_offering_id)
  setOfferingDocuments(offering.value.id)
})

// Functions
async function setAccounts() {
  loadingAccounts.value = true;
  const promises = [];
  promises.push(accountsStore.setHoldingAccount());
  promises.push(accountsStore.setMyAccounts());
  await Promise.all(promises);
  // Set account names
  if (accountsStore?.myAccounts?.length > 0) selectedAccount.value = accountsStore.myAccounts[0]

  loadingAccounts.value = false;
}

async function setOffering(offering_id) {
  offering.value = await offeringsStore.getOfferingById(offering_id)
  if (!offering.value) notify('failure', 'Sorry', 'Sorry we were unable to load this offering. Please check your internet connection and try again. If the issue persists, please contact tech support')
}

async function setOfferingDetails(tapi_offering_id) {
  isLoadingOfferingDetails.value = true
  offeringDetails.value = await offeringsStore.getOfferingDetails(tapi_offering_id)
  isLoadingOfferingDetails.value = false
}

async function setOfferingDocuments(offering_id) {
  isLoadingOfferingDocs.value = true
  docs.value = await offeringsStore.getOfferingDocumentsSupabase(offering_id)
  // docs.value = await offeringsStore.getOfferingDocuments(tapi_offering_id)
  isLoadingOfferingDocs.value = false
}

// async function checkForExternalAccount(id, tapi_account_id) {
//   const { statusCode } = await accountsStore.getAchAccount(id, tapi_account_id)
//   if (statusCode == '101' || statusCode == 101) return true
//   return false
// }


// Simple Functions
const investPopup = () => {
  // Validate
  if (route.name == 'edit-offering') return
  if (buttonClass.value == 'button_disabled') return
  if (!userStore.user) notify('failure', 'Sorry', 'Please login before making an investment, you may need to reload the window.')
  if (!parseInt(numShares.value.replace(/[^0-9]/g, ''))) return

  // Make sure investment value is great then minimum investment amount
  if (investmentTotal.value < offering.value.minimum) {
    notify('failure', 'Sorry', 'The investment amount is less than the minimum investment amount.')
    return
  }

  // Set loading
  loadingInvestPopup.value = true

  trade.value = {
    transactionUnits: parseInt(numShares.value.replace(/[^0-9]/g, '')),
    investmentTotal: investmentTotal.value,
  }
  if (!selectedAccount.value) selectedAccount.value = accountsStore.holdingAccount

  // Set investment flow type: direct-invest-new, direct-invest-existing, custody-invest
  need.value = getInvestmentFlowType(selectedAccount.value, partnerStore.partner.allow_custody_accounts, offering.value.is_custody_only)

  showFlow.value = true
  loadingInvestPopup.value = false
}

const getInvestmentFlowType = (selected_account, allow_custody_accounts, is_custody_only) => {
  if (is_custody_only && allow_custody_accounts) return 'create-custody-investment'
  if (selected_account?.accounts_protected?.is_holding_account) return 'create-direct-investment'
  if (selected_account && !selected_account?.accounts_protected?.is_holding_account && allow_custody_accounts) return 'create-custody-investment'
  return 'create-direct-investment'
}

const checkReadyToInvest = () => {
  if (!numShares.value) buttonClass.value = 'button_disabled'
  else if (investmentTotal.value < offering.value.minimum) buttonClass.value = 'button_disabled'
  else if (investmentTotal.value > (offering.value.remainingShares * offering.value.unitPrice)) buttonClass.value = 'button_disabled'
  else buttonClass.value = 'button_invest'
}

const setSection = (selectedSection) => {
  if (selectedSection == 'overview') {
    visibleSection.value = 'overview'
    overviewClass.value = 'bg-white text-operacolor'
    scorecardsClass.value = ''
    documentsClass.value = ''
    forumClass.value = ''
  }
  else if (selectedSection == 'scorecards') {
    visibleSection.value = 'scorecards'
    overviewClass.value = ''
    scorecardsClass.value = 'bg-white text-operacolor'
    documentsClass.value = ''
    forumClass.value = ''
  }
  else if (selectedSection == 'forum') {
    visibleSection.value = 'forum'
    overviewClass.value = ''
    forumClass.value = 'bg-white text-operacolor'
    scorecardsClass.value = ''
    documentsClass.value = ''
  }
  else {
    visibleSection.value = 'documents'
    overviewClass.value = ''
    scorecardsClass.value = ''
    forumClass.value = ''
    documentsClass.value = 'bg-white text-operacolor'
  }
}

function setSocials(offering) {
  if (offering.linkedin) {
    socials.value.push(
      {
        name: 'LinkedIn',
        href: offering.linkedin,
        icon: markRaw(defineComponent({
          render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 20 20' }, [
              h('path', {
                d: 'M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z',
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd',
              },),
            ]),
        })),
      },
    )
  }
  if (offering.instagram) {
    socials.value.push(
      {
        name: 'Instagram',
        href: offering.instagram,
        icon: markRaw(defineComponent({
          render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                'clip-rule': 'evenodd',
              }),
            ]),
        })),
      }
    )
  }
  if (offering.facebook) {
    socials.value.push(
      {
        name: 'Facebook',
        href: offering.facebook,
        icon: markRaw(defineComponent({
          render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                'clip-rule': 'evenodd',
              }),
            ]),
        })),
      }
    )
  }
  if (offering.twitter) {
    socials.value.push(
      {
        name: 'Twitter',
        href: offering.twitter,
        icon: markRaw(defineComponent({
          render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
              }),
            ]),
        })),
      },
    )
  }
  if (offering.website) {
    socials.value.push(
      {
        name: 'Site',
        href: offering.website,
        icon: GlobeAltIcon,
      },
    )
  }
  if (offering.additional_link) {
    socials.value.push(
      {
        name: 'Fact Right',
        href: offering.additional_link,
        icon: LinkIcon,
      },
    )
  }
}

const handleError = (errorMessage) => {
  notify('failure', 'Sorry', errorMessage)
}

const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}

// Watchers
watch(numShares, () => {
  numShares.value = numShares.value.replace(/[^,0-9]/g, '')
  numShares.value = numShares.value.replace(/,/gi, "")
  numShares.value = numShares.value.split(/(?=(?:\d{3})+$)/).join(",")
  if (numShares.value) {
    investmentTotal.value = parseFloat(numShares.value.replace(/[^0-9]/g, '')) * parseFloat(offering.value.unit_price)
  } else {
    investmentTotal.value = 0
  }
  checkReadyToInvest()
})

</script>