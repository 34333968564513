<template>
  <div class="w-full">
    <div v-if="loading" class="w-full flex items-center justify-center">
      <div class="w-20 h-20">
        <SpinnerFlip />
      </div>
    </div>
    <div v-else class="w-full">
      <!-- Previous Comments -->
      <ul role="list" class="space-y-6 max-h-[500px] overflow-y-scroll">
        <li v-if="comments.length <= 0" class="font-bold dark:text-neutral-400 flex gap-2 items-center justify-center py-8">No Comments
          <ChatBubbleLeftRightIcon class="size-6" />
        </li>
        <li v-for="(comment, commentIndex) in comments" :key="comment.id" @click="handleCommentClick(comment)" class="relative flex gap-x-4 cursor-pointer ml-1">
          <div :class="[commentIndex === comments.length - 1 ? 'h-6' : '-bottom-6', 'absolute left-0 top-0 flex w-6 justify-center']">
            <div class="w-px bg-gray-300 dark:bg-neutral-600"></div>
          </div>
          <template v-if="comment.viewing">
            <!-- <img :src="comment.person.imageUrl" alt="" class="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50" /> -->
            <span
              :class="[getCommentBgColor(comment.comment_mood), (getCommentBgColor(comment.comment_mood) == 'bg-gray-400') ? 'px-2' : '', 'flex h-8 w-8 items-center justify-center rounded-full z-10 -ml-1']">
              <component :is="getCommentIcon(comment.comment_mood)" class="h-5 w-5 text-white" aria-hidden="true" />
            </span>
            <!-- <div class="mt-3 size-6 flex-none rounded-full bg-green-600"></div> -->
            <div class="flex-auto rounded-md p-3 bg-white shadow dark:shadow-none dark:bg-neutral-900 w-52">
              <div class="flex justify-between gap-x-4">
                <div class="py-0.5 text-xs leading-5 text-gray-500 dark:text-neutral-500">
                  <span class="font-medium text-gray-900 dark:text-neutral-100">{{ comment.commentor_name }}</span>
                </div>
                <time :datetime="comment.created_at" class="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-neutral-500">{{ timeAgo(comment.created_at) }}</time>
              </div>
              <p class="text-sm leading-6 text-gray-500 dark:text-neutral-400">{{ comment.comment }}</p>
            </div>
          </template>
          <template v-else>
            <div class="relative flex h-6 w-6 flex-none items-center justify-center">
              <!-- <CheckCircleIcon v-if="comment.type === 'paid'" class="h-6 w-6 text-indigo-600" aria-hidden="true" /> -->
              <div class="h-1.5 w-1.5 rounded-full bg-white dark:bg-neutral-950 dark:ring-neutral-600 ring-1 ring-gray-300"></div>
            </div>
            <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500 dark:text-neutral-500 w-52 truncate">
              <span class="font-medium text-gray-900 dark:text-neutral-100">{{ comment.commentor_name }}</span> {{ comment.comment }}
            </p>
            <time :datetime="comment.created_at" class="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-neutral-500">{{ timeAgo(comment.created_at) }}</time>
          </template>
          <TrashIcon
            v-if="userStore?.user?.profiles_protected.role == 'super_admin' || (userStore?.user?.profiles_protected?.role == 'partner_admin' && userStore?.user?.profiles_protected?.partner_id == comment.partner_id)"
            @click.stop="handleDeleteComment(comment)"
            class="size-4 text-black dark:text-neutral-300 hover:text-red-500 dark:hover:text-red-500 transition ease-in-out duration-200 hidden sm:block mt-1" />
        </li>
      </ul>

      <!-- New comment form -->
      <div class="mt-6 flex gap-x-3">
        <UserAvatar :avatar="userStore?.user?.avatar" :user_id="userStore?.user?.id" :first_name="userStore?.user?.parties?.first_name" :last_name="userStore?.user?.parties?.last_name" size="xs" />
        <form @submit.prevent="postComment(props.offering_id, userStore?.user?.profiles_protected?.partner_id, new_comment, selected.value, userStore?.user?.parties)" class="relative flex-auto">
          <div
            class="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 dark:focus-within:ring-operacolor dark:focus-within:ring-2 focus-within:ring-2 focus-within:ring-operacolor bg-white dark:bg-neutral-900">
            <label for="comment" class="sr-only">Add your comment</label>
            <textarea rows="4" name="comment" id="comment" v-model="new_comment"
              class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 dark:text-neutral-200 placeholder:text-gray-400 dark:placeholder:text-neutral-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."></textarea>
          </div>

          <div class="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div class="flex items-center space-x-5">
              <div class="flex items-center">
                <Listbox as="div" v-model="selected">
                  <ListboxLabel class="sr-only">Your mood</ListboxLabel>
                  <div class="relative">
                    <ListboxButton class="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                      <span class="flex items-center justify-center">
                        <span v-if="selected.value === null">
                          <FaceSmileIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span class="sr-only">Add your mood</span>
                        </span>
                        <span v-if="!(selected.value === null)">
                          <span :class="[selected.bgColor, 'flex h-8 w-8 items-center justify-center rounded-full']">
                            <component :is="selected.icon" class="h-5 w-5 flex-shrink-0 text-white" aria-hidden="true" />
                          </span>
                          <span class="sr-only">{{ selected.name }}</span>
                        </span>
                      </span>
                    </ListboxButton>

                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                      <ListboxOptions
                        class="absolute bottom-10 z-10 -ml-6 w-60 rounded-lg bg-white dark:bg-neutral-800 py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                        <ListboxOption as="template" v-for="mood in moods" :key="mood.value" :value="mood" v-slot="{ active }">
                          <li :class="[active ? 'bg-gray-100 dark:bg-neutral-900' : '', 'relative select-none px-3 py-2 cursor-pointer']">
                            <div class="flex items-center">
                              <div :class="[mood.bgColor, 'flex h-8 w-8 items-center justify-center rounded-full']">
                                <component :is="mood.icon" :class="[mood.iconColor, 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
                              </div>
                              <span class="ml-3 block dark:text-neutral-300 truncate font-medium">{{ mood.name }}</span>
                            </div>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>
            <button type="submit" class="button_secondary">Post Comment</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue'
import { useCommentsStore } from '@/stores/comments';
import { useUserStore } from '@/stores/user';
// Components
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue'
import UserAvatar from '@/components/ui/UserAvatar.vue'
// Libraries
import { FaceFrownIcon, FaceSmileIcon, FireIcon, HandThumbUpIcon, HeartIcon, XMarkIcon, ChatBubbleLeftRightIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/vue/20/solid'
import { TrashIcon } from '@heroicons/vue/24/outline';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
// Props
const props = defineProps({
  offering_id: { type: String, required: true },
})
// Stores
const commentsStore = useCommentsStore()
const userStore = useUserStore()
// Globals
const loading = ref(true)
const comments = ref([])
const new_comment = ref('')
const moods = [
  { name: 'Excited', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
  { name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400' },
  { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
  { name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
  { name: 'Thumbsy', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500' },
  { name: 'I feel nothing', value: null, icon: XMarkIcon, iconColor: 'text-gray-400 dark:text-neutral-400', bgColor: 'bg-transparent' },
]
const selected = ref(moods[5])
// Mounted
onMounted(async () => {
  // Set Comments
  await setComments(props.offering_id)
  // Ready
  loading.value = false
})

// Functions
async function setComments(offering_id) {
  comments.value = await commentsStore.getOfferingComments(offering_id)
}

async function postComment(offering_id, partner_id, comment, comment_mood, commentor) {
  // Get Commentor Name
  let commentor_name = 'Anonymous'
  if (commentor) commentor_name = `${commentor.first_name ?? 'Anonymous'} ${commentor.last_name ?? ''}`
  // Post Comment
  await commentsStore.insertComment(offering_id, partner_id, comment, commentor_name, comment_mood)
  // Reset Form
  new_comment.value = ''
  selected.value = moods[5]
  // Set Comments
  await setComments(offering_id)
}

// Simple Functions
const handleCommentClick = (comment) => {
  const isViewing = comment.viewing
  comments.value.forEach((c) => c.viewing = false)
  if (isViewing) comment.viewing = false
  else comment.viewing = true
}

const handleDeleteComment = async (comment) => {
  // TODO add a warning popup before deleting
  await commentsStore.deleteComment(comment.id)
  await setComments(props.offering_id)
}

const getCommentBgColor = (mood) => {
  const moodObj = moods.find(m => m.value === mood)
  if (moodObj?.bgColor == 'bg-transparent') return 'bg-gray-400'
  return moodObj ? moodObj.bgColor : 'bg-gray-400'
}

const getCommentIcon = (mood) => {
  const moodObj = moods.find(m => m.value === mood)
  if (moodObj?.icon == XMarkIcon) return ChatBubbleBottomCenterTextIcon
  return moodObj ? moodObj.icon : ChatBubbleBottomCenterTextIcon
}

const timeAgo = (timestamp) => {
  const now = new Date();
  const then = new Date(timestamp);
  const diffInSeconds = Math.floor((now - then) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = secondsInMinute * 60;
  const secondsInDay = secondsInHour * 24;

  if (diffInSeconds < secondsInMinute) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < secondsInHour) {
    const minutes = Math.floor(diffInSeconds / secondsInMinute);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < secondsInDay) {
    const hours = Math.floor(diffInSeconds / secondsInHour);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(diffInSeconds / secondsInDay);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
}
</script>