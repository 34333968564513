import { z } from 'zod'

const AllowedAccountTypeEnum = z.enum(['individual', 'joint', 'entity']);

export const PartnerSettingsSchema = z.object({
  logo_text: z.string({ message: "Logo Text must be a string" }).optional().nullable(),
  redirect_url: z.string({ message: "Redirect URL must be a string" }).optional().nullable(),
  support_email: z.string({ message: "Support Email must be a string" }).email({ message: "Support Email must be a valid email" }),
  bcc_email: z.string({ message: "Bcc Email must be a string" }).email({ message: "Bcc Email must be a valid email" }).optional(),
  title_color: z.string({ message: "Title Color must be a string" }),
  title_color_dark: z.string({ message: "Title Color Dark must be a string" }),
  primary_color: z.string({ message: "Primary Color must be a string" }),
  primary_color_light: z.string({ message: "Primary Color Light must be a string" }),
  primary_color_dark: z.string({ message: "Primary Color Dark must be a string" }),
  disable_signup: z.boolean({ message: "Disable Sign Up must be a boolean" }),
  send_welcome_email: z.boolean({ message: "Send Welcome Email must be a boolean" }),
  welcome_email_content: z.string({ message: "Welcome Email Content must be a string" }).optional().nullable(),
  use_additional_signin_content: z.boolean({ message: "Add link to Sign in Email must be a boolean" }),
  additional_signin_content: z.string({ message: "Add Link Content must be a string" }).optional().nullable(),
  use_custom_thank_you_email: z.boolean({ message: "Use Custom Thank You Email must be a boolean" }),
  thank_you_email_content: z.string({ message: "Thank You Email Content must be a string" }).optional().nullable(),
  collect_income_data: z.boolean({ message: "Collect Income Data must be a boolean" }),
  collect_employer_data: z.boolean({ message: "Collect Employer Data must be a boolean" }),
  send_accreditation_notifications: z.boolean({ message: "Send Accreditation Status Notifications must be a boolean" }),
  allowed_account_types: z.array(AllowedAccountTypeEnum).min(1, { message: "At least one account type must be allowed" }),
}).refine(data => {
  // Check if 'send_welcome_email' is true and 'welcome_email_content' is missing
  return !data.send_welcome_email || data.welcome_email_content;
}, {
  path: ['welcome_email_content'],
  message: 'Welcome Email Content is required when Send Welcome Email is toggled on.',
}).refine(data => {
  // Check if 'use_additional_signin_content' is true and 'additional_signin_content' is missing
  return !data.use_additional_signin_content || data.additional_signin_content;
}, {
  path: ['additional_signin_content'],
  message: 'Add Link Content is required when Add link to Sign in Email is toggled on.',
}).refine(data => {
  // Check if 'use_custom_thank_you_email' is true and 'thank_you_email_content' is missing
  return !data.use_custom_thank_you_email || data.thank_you_email_content;
}, {
  path: ['thank_you_email_content'],
  message: 'Thank You Email Content is required when Use Custom Thank You Email is toggled on.',
})