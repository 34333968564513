<template>
  <Slideover v-model="offering.show_content" header="Offering Content">
    <div class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full">

      <div v-if="filteredContent.length > 0" class="flex flex-col gap-5 w-full">

        <div v-if="filteredContent?.length > 0" class="flex flex-col gap-5">
          <div v-for="(added_content) in filteredContent" :key="added_content.id">

            <div v-if="added_content.type == 'content'" class="relative bg-white dark:bg-neutral-900 shadow p-5 rounded-lg flex flex-col gap-5">
              <!-- Header -->
              <Input label="Header" id="contentHeader" placeholder="ex. Mission Statement" v-model="added_content.header" class="flex-grow w-96" />

              <!-- Paragraph Textarea -->
              <TextareaCustom label="Content" :rows="3" v-model="added_content.text" :required="true" class="flex-1" />

              <!-- Added Content Delete -->
              <div class="absolute top-5 right-5">
                <TrashIcon @click="offering?.content?.splice(offering.content.findIndex(item => item.id === added_content.id), 1)"
                  class="size-5 mb-3 cursor-pointer hover:text-red-700 dark:text-neutral-200 transition-all ease-in-out duration-300" />
              </div>
            </div>


            <div v-if="added_content.type == 'image'" class="relative bg-white dark:bg-neutral-900 shadow p-5 rounded-lg">
              <div>
                <label class="!flex items-center gap-2 input_label">
                  Image
                  <Tooltip content="The smaller this file is the faster it will load when displayed to investors, we recommend a file size of 1 MB or less.">
                    <QuestionMarkCircleIcon class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight" />
                  </Tooltip>
                </label>
                <div class="flex items-center mt-2 gap-x-3">
                  <img v-if="added_content.url" :src="added_content.url" alt="Uploaded Image" class="w-24 h-12 rounded-lg object-fit">
                  <PhotoIcon v-else class="w-12 h-12 text-gray-300" aria-hidden="true" />
                  <label :for="`content-image-upload-edit-${added_content.id}`" type="button" class="button_secondary">Change</label>
                  <input :id="`content-image-upload-edit-${added_content.id}`" :name="`content-image-upload-edit-${added_content.id}`" type="file" class="sr-only" accept="image/*"
                    @change="handleContentImagesArrayChanged($event, added_content.id)" />
                </div>
              </div>
              <!-- Image Delete -->
              <div class="absolute top-5 right-5">
                <TrashIcon @click="offering?.content?.splice(offering.content.findIndex(item => item.id === added_content.id), 1)"
                  class="size-5 mb-3 cursor-pointer hover:text-red-700 dark:text-neutral-200 transition-all ease-in-out duration-300" />
              </div>
            </div>

          </div>
        </div>

        <!-- Add Content button -->
        <div class="self-center">
          <Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton>
                <ButtonSecondary text="Add Content" size="lg" :icon="PlusIcon" />
              </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-neutral-700 rounded-md bg-white dark:bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                  <a @click="offering?.content?.push({ id: `${uuidv4()}`, header: '', text: '', type: 'content' })"
                    :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-100' : 'text-gray-700 dark:text-neutral-400', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
                    <Bars3BottomLeftIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-neutral-300 group-hover:dark:text-neutral-300" aria-hidden="true" />
                    Add a paragraph
                  </a>
                  </MenuItem>
                </div>
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                  <a @click="offering?.content?.push({ id: `${uuidv4()}`, name: '', type: 'image' })"
                    :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-100' : 'text-gray-700 dark:text-neutral-400', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
                    <PhotoIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-neutral-300 group-hover:dark:text-neutral-300" aria-hidden="true" />
                    Add an image
                  </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>

      <!-- Empty State -->
      <div v-else class="text-center sm:mt-10">
        <RectangleStackIcon class="mx-auto size-12 text-gray-400 dark:text-neutral-500" />
        <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-white">No Content</h3>
        <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">Get started by creating a new paragraph or image</p>
        <div class="mt-6">

          <Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton>
                <ButtonPrimary text="New Content" size="xl" :icon="SquaresPlusIcon" />
              </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-neutral-700 rounded-md bg-white dark:bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                  <a @click="offering?.content?.push({ id: `${uuidv4()}`, header: '', text: '', type: 'content' })"
                    :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-100' : 'text-gray-700 dark:text-neutral-400', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
                    <Bars3BottomLeftIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-neutral-300 group-hover:dark:text-neutral-300" aria-hidden="true" />
                    Add a paragraph
                  </a>
                  </MenuItem>
                </div>
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                  <a @click="offering?.content?.push({ id: `${uuidv4()}`, name: '', type: 'image' })"
                    :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-100' : 'text-gray-700 dark:text-neutral-400', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
                    <PhotoIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-neutral-300 group-hover:dark:text-neutral-300" aria-hidden="true" />
                    Add an image
                  </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>

      <!-- Buttons -->
      <div class="flex justify-end gap-5">
        <ButtonSecondary text="Close" size="lg" @click="offering.show_content = false" class="w-full mt-12" />
      </div>

    </div>
  </Slideover>
</template>

<script setup>
// Essentials
import { computed } from 'vue'
import { v4 as uuidv4 } from 'uuid';

// Components
import Slideover from '@/components/applicationui/Slideover.vue';
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';
import Input from '@/components/applicationui/Input.vue';
import Tooltip from '@/components/applicationui/Tooltip.vue';
import TextareaCustom from '@/components/applicationui/TextareaCustom.vue';

// Libraries
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { PhotoIcon, TrashIcon, QuestionMarkCircleIcon, SquaresPlusIcon } from '@heroicons/vue/24/outline';
import { Bars3BottomLeftIcon, PlusIcon, RectangleStackIcon } from '@heroicons/vue/20/solid'

// Model
const offering = defineModel()

// Computed
const filteredContent = computed(() => {
  if (!offering.value.content) return []
  return offering.value.content.filter(obj => obj.type == 'content' || obj.type == 'image')
});

// Upload Image
const handleContentImagesArrayChanged = (event, id) => {
  if (!event || (event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/png')) {
    // Handle error (Show a error message here)
    return
  }

  const obj_index = offering.value.content.findIndex(item => item.id === id)
  offering.value.content[obj_index].name = event.target.files[0].name
  offering.value.content[obj_index].file = event.target.files[0]
  offering.value.content[obj_index].url = URL.createObjectURL(event.target.files[0])
}
</script>