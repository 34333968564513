<template>
  <Slideover v-model="offering.show_settings" header="Offering Settings">
    <div class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full">

      <div class="flex flex-col gap-5">
        <!-- Show Scores -->
        <Switch v-if="partnerStore.partner.partners_protected.show_scores" v-model="offering.show_score" label="Show Opera Score"
          description="Do you want to display the Opera score for this offering users?" />

        <!-- Accredited Investors Only -->
        <Switch v-model="offering.require_accreditation" label="Accredited Investors Only" description="Require all investors to be accredited before their investments can be finalized?" />

        <!-- Show Factright Report -->
        <Switch v-if="isSandbox" v-model="offering.show_factright_report" label="Show Factright Report" description="Show the Factright report to potential investors?" />

        <!-- Use RegCF -->
        <Switch v-model="offering.use_regcf_protocols" label="Use RegCF Protocols" description="Enabling this will enable all RegCF compliant behavior" />

        <!-- Show Forum Section -->
        <Switch v-model="offering.show_forum" :disabled="offering.use_regcf_protocols" label="Show Forum Section" description="Do you want to include a public forum section for this offering?" />

        <!-- Allowed Account Types -->
        <div class="border-t border-dashed border-gray-300 dark:border-neutral-700 pt-5">
          <fieldset>
            <legend class="text-base font-semibold leading-6 text-gray-900 dark:text-white flex gap-1 items-center">
              Allowed Account Types
              <Tooltip
                content="Select all the types of investors this offering allows. At least one is required. Please note that your site settings also has a list of allowed account type, for an account type to be available it must be selected in both your site settings and here in the offering settings.">
                <QuestionMarkCircleIcon class="size-5 text-gray-600 cursor-pointer dark:text-neutral-400" />
              </Tooltip>
            </legend>
            <div class="mt-4 divide-y divide-gray-200 border-t border-gray-200 dark:border-neutral-700 dark:divide-neutral-700">
              <div class="relative flex items-start py-4">
                <Checkbox label="Individual Accounts" v-model="allow_individual_account" class="pl-1" />
              </div>
              <div class="relative flex items-start py-4">
                <Checkbox label="Joint Accounts" v-model="allow_joint_account" class="pl-1" />
              </div>
              <div class="relative flex items-start py-4">
                <Checkbox label="Entity Accounts" v-model="allow_entity_account" class="pl-1" />
              </div>
            </div>
          </fieldset>
        </div>

      </div>

      <!-- Buttons -->
      <ButtonSecondary text="Close" size="lg" @click="setAllowedAccountTypes" class="w-full mt-10" />
    </div>
  </Slideover>
</template>

<script setup>
// Essentials
import { ref, computed, watch, onMounted } from 'vue'

// Components
import Slideover from '@/components/applicationui/Slideover.vue';
import Switch from '@/components/applicationui/Switch.vue'
import Checkbox from '@/components/applicationui/Checkbox.vue'
import Tooltip from '@/components/applicationui/Tooltip.vue'
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';

// Libraries
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'

// Stores
import { usePartnerStore } from '@/stores/partner';
const partnerStore = usePartnerStore();

// Computed
const isSandbox = computed(() => {
  if (window.location.hostname == 'sandbox.operacore.com') return true
  return false
});

// Model
const offering = defineModel();

// Allowed Account Types
const allow_individual_account = ref(false)
const allow_joint_account = ref(false)
const allow_entity_account = ref(false)

const setAllowedAccountTypes = () => {
  if (allow_individual_account.value) {
    if (!offering.value.allowed_account_types.includes('individual')) offering.value.allowed_account_type.push('individual')
  } else {
    const index = offering.value.allowed_account_types.indexOf('individual');
    if (index > -1) offering.value.allowed_account_types.splice(index, 1);
  }
  if (allow_joint_account.value) {
    if (!offering.value.allowed_account_types.includes('joint')) offering.value.allowed_account_types.push('joint')
  } else {
    const index = offering.value.allowed_account_types.indexOf('joint');
    if (index > -1) offering.value.allowed_account_types.splice(index, 1);
  }
  if (allow_entity_account.value) {
    if (!offering.value.allowed_account_types.includes('entity')) offering.value.allowed_account_types.push('entity')
  } else {
    const index = offering.value.allowed_account_types.indexOf('entity');
    if (index > -1) offering.value.allowed_account_types.splice(index, 1);
  }
  offering.value.show_settings = false
}

watch([allow_individual_account, allow_joint_account, allow_entity_account], (newValues) => {
  if (!newValues.includes(true)) {
    setTimeout(() => {
      allow_individual_account.value = true;
    }, 500)
  }
})

watch(() => offering.value.show_settings, (newVal) => {
  if (!newVal) {
    setAllowedAccountTypes();
  }
});

watch(() => offering.value.use_regcf_protocols, () => {
  if (offering.value.use_regcf_protocols) {
    offering.value.show_forum = true
    offering.value.visibility = 'public'
  }
}, { deep: true })

watch(() => offering.value.show_forum, () => {
  if (offering.value.use_regcf_protocols && !offering.value.show_forum) offering.value.show_forum = true
}, { deep: true })

// Mounted
onMounted(async () => {
  // Initialize checkboxes
  allow_individual_account.value = offering.value.allowed_account_types.includes('individual');
  allow_joint_account.value = offering.value.allowed_account_types.includes('joint');
  allow_entity_account.value = offering.value.allowed_account_types.includes('entity');
  // Ensure at least one account type is allowed
  if (!allow_individual_account.value && !allow_joint_account.value && !allow_entity_account.value) allow_individual_account.value = true
})
</script>